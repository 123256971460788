import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from '../../contexts/UserContext';
import { getQuizAttempts, getQuizInfo, getQuizAttemptPage, startQuizAttempt, saveQuizAttempt, finishQuizAttempt, viewQuiz } from '../../api/modules';
import $ from 'jquery';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Sticky from 'react-sticky-el';
import { getUnits } from "../../api/courses";
import { setUnits, useCoursesContext } from "../../contexts/CoursesContext";

function Quiz({ currentModule }) {

    const [ optionalQuiz, setOptionalQuiz ] = useState(true);
    const [ startOptionalQuiz, setStartOptionalQuiz ] = useState(false);
    const { courseId } = useParams();
    const { user } = useUserContext()
    const [ quizContent, setQuizContent ] = useState(null)
    const [ attempts, setAttempts ] = useState(false)
    const [ currentAttemptPage, setCurrentAttemptPage ] = useState(false);
    const [ askIfNewAttempt, setAskIfNewAttempt ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ confirmNewAttempt, setConfirmNewAttempt ] = useState(false)
    const { courses : { allUnits }, dispatch } = useCoursesContext()


    useEffect(() => {
      console.log(window.innerWidth)
    }, []);
    useEffect(() => {
      if(currentModule) {
        fetchModuleContent(currentModule)
      }
    }, [currentModule])

    useEffect(()=>{
      if(attempts){
        if(attempts.length > 0){
          //check to see what the last attempt status is
          //find any attempts that contain state === inprogress
          const inProgress = attempts.find(attempt => attempt.state === "inprogress")
          const completed = attempts.find(attempt => attempt.state === "finished")
          if(inProgress){
            //theres an attempt in progress so load it in
            fetchQuizAttempt(inProgress)
          }
          else if(completed) {
            //theres a finished attempt, ask if they want to start another attempt which will erase progress
            setAskIfNewAttempt(true)
            setLoading(false)
          }
        }
        else {
          //there are no attempts so start a new one 
          startAttempt()

        }
      }
    },[attempts])

    useEffect(() => {
      $(document).off('change', '.quiz-question .form-check-input input, .quiz-question select')
      $(document).on('change', '.quiz-question .form-check-input input, .quiz-question select', questionChange);
      $(document).off('input', '.quiz-question input, .quiz-question textarea');
      $(document).on('input', '.quiz-question input, .quiz-question textarea', questionChange);
    }, [currentAttemptPage])


    const questionChange = function() {
      var slot = $(this).closest('.quiz-question').data('slot');
      // Get all inputs or selects in this question
      var questions = [];
      $(this).closest('.quiz-question').find('input, select, textarea').each(function() {
        if($(this).is('input:checked') || ($(this).is('select') && $(this).val() !== '') || !$(this).hasClass('form-check-input') && $(this).attr("id") && $(this).attr("id").includes("_answer") || $(this).is('textarea') && $(this).val()) {
          questions.push({
            name : $(this).attr('name'),
            val : $(this).val()
          })
        }
      });
      var sequenceCheckName = `${questions[0].name.split('_')[0]}_:sequencecheck`;
      var sequenceCheckVal = $(`input[name="${sequenceCheckName}"]`).val();
      var sequence = {
        name : sequenceCheckName,
        val : sequenceCheckVal
      }
      saveAttempt(currentAttemptPage.attempt, slot, questions, sequence)
    }

    const fetchModuleContent = async (mod) => {
      try {
        const quizInfo = await getQuizInfo(courseId, mod, user.token);
        setQuizContent(quizInfo)
        const viewed = await viewQuiz(mod, user.token);
        const modAttempts = await getQuizAttempts(mod, user.userid, user.token);
        setAttempts(modAttempts)
      } catch (error) {
        console.log('error fetching quiz');
      }
    }

    const startAttempt = async () => {
      try {
        const attempt = await startQuizAttempt(currentModule, user.token);
        fetchQuizAttempt(attempt)
      } catch (error) {
        console.log(error, 'error starting attempt')
      }
      setLoading(false)
    }

    const saveAttempt = async (attempt, slot, question, sequence) => {
      try {
        console.log(question)
        const savedAttempt = await saveQuizAttempt(attempt, slot, question, sequence, user.token);
        // setCurrentAttemptPage(attemptPage)
      } catch (error) {
        console.log(error, 'error saving attempt')
      }
    }

    const finishAttempt = async () => {
      try {
        const savedAttempt = await finishQuizAttempt(currentAttemptPage.attempt, user.token);
        fetchModuleContent(currentModule)

        const units = await getUnits(courseId, user.token);
        console.log('here', units)
        dispatch(setUnits(units))
      } catch (error) {
        console.log(error, 'error saving attempt')
      }
    }

    const fetchQuizAttempt = async (attempt) => {
      try {
        const attemptPage = await getQuizAttemptPage(attempt, 0, user.token);
        setCurrentAttemptPage(attemptPage)
        setAskIfNewAttempt(false)
      } catch (error) {
        console.log('error fetching attempt')
      }
      setLoading(false)
    }

    const getQuizVideoURL = () => {
      const ele = document.createElement('div')
      ele.innerHTML = quizContent.intro;
      document.body.appendChild(ele);
      const videoQuizDiv = document.getElementById('id_fnesc-custom-quiz')
      const videoQuizAttr = videoQuizDiv.dataset.url
      const embedURL = videoQuizAttr.replace('watch?v=', 'embed/')
      ele.remove()
      return embedURL;
    }

    const isQuizVideo = () => {
      if(quizContent) {
        if(quizContent.intro.indexOf('fnesc-custom-quiz-div-video') > -1) {
          return true;
        }
      }
      return false;
    }

    const skipOptionalQuiz = async () => {
       try {

         let startAttempt;
         let attemptPage = currentAttemptPage
         if(currentAttemptPage){
           startAttempt = attempts[attempts.length - 1]
           
          }
          else {
            startAttempt = await startQuizAttempt(currentModule, user.token);
            attemptPage = await getQuizAttemptPage(startAttempt, 0, user.token);


          }
          console.log(startAttempt)
        const promises = attemptPage.questions.map(async attempt => {
          console.log("here")
          const id = $(attempt.html)[0].id.split('-');
          const name = `q${id[1]}:${id[2]}_answer`
          const answer = [{
            name: name,
            val: "Did Not Answer"
          }]

          const sequence = {
            name: `q${id[1]}:${id[2]}_:sequencecheck`,
            val: attempt.sequencecheck
          }

          return await saveQuizAttempt(startAttempt, attempt.slot, answer, sequence, user.token);

        })
        const attemptsResolve = await Promise.all(promises)
        const finishAttempt = await finishQuizAttempt(startAttempt, user.token);
        console.log(finishAttempt)

      } catch (error) {
        console.log(error, 'error saving attempt')
      }

    }

    return (
      <div>
         <Modal size="sm" show={confirmNewAttempt}>
           <Modal.Header><h4>Are you sure?</h4></Modal.Header>
            <Modal.Body>
              <p style={{overflowWrap:'anywhere'}}>
                Previous quiz submission will be removed by starting a new attempt.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=> setConfirmNewAttempt(false)}>Cancel</Button>
              <Button onClick={()=> {startAttempt(); setConfirmNewAttempt(false)}}>Start Attempt</Button>

            </Modal.Footer>
        </Modal>
        {
          !loading ?
          
            optionalQuiz && !startOptionalQuiz ? 
              <div style={{display:'flex', alignItems:'center',flexDirection:'column'}} >
                <h4>This quiz is optional</h4>
                <div>
                  <Button onClick={()=> setStartOptionalQuiz(true)} style={{width:'fit-content', marginRight:10}}>Start</Button> 
                  <Button onClick={skipOptionalQuiz} style={{width:'fit-content'}}>Skip</Button> 

                </div>
              </div>
              :
            <>
                <h2 style={{textAlign:'center'}}>{currentModule.name}</h2>
              {!askIfNewAttempt && <div style={{textAlign:'center', margin:'25px'}} dangerouslySetInnerHTML={{__html : quizContent.intro }}></div>}
                {askIfNewAttempt && <div style={{display:'flex', justifyContent:'center'}} ><Button onClick={() => setConfirmNewAttempt(true)}>Start new attempt</Button> </div>}
                <div style={{display:'flex', flexDirection: isQuizVideo() && window.innerWidth > 992? 'row' :"column-reverse", justifyContent:'space-evenly'}}>

                  <div style={{width: isQuizVideo()&& window.innerWidth > 992 ? '50%': "100%", display:"flex", flexDirection:"column"}} >
                    {currentAttemptPage && !askIfNewAttempt && currentAttemptPage.questions.map((question, i) => {
                      let newHtml = question.html.replaceAll('input type="radio"', 'input type="radio" class="form-check-input"')
                      newHtml = newHtml.replaceAll('<select ','<select class="form-select"')
                      return (
                        <div key={`question-${i}`} className="quiz-question" data-slot={question.slot} dangerouslySetInnerHTML={{__html : newHtml }}></div>
                      )
                    })}

                    {!askIfNewAttempt && <Button style={{marginTop: '25px', alignSelf:'center'}} onClick={() => finishAttempt()}>Finish Attempt</Button>}
                    
                  </div>
                  <div style={{width: isQuizVideo() && window.innerWidth > 992 ? '50%': "100%"}}>
                    {quizContent && !askIfNewAttempt ?
                        <Sticky topOffset={-100}>
                      <div>
                        {isQuizVideo() ?
                          <iframe
                            width="100%"
                            height="225px"
                            src={getQuizVideoURL()}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                        : false}
                      </div>
                        </Sticky>
                    : false}
                  </div>
                </div>
            </>
          
          
          
          :
          <div style={{display:"flex", justifyContent:'center'}}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>

          </div>
        }
      </div>
    )
  }
  
  export default Quiz;
  
  // {/* <h4>In Progress</h4> */}
  // {/* {attempts && attempts.filter(attempt => attempt.state !== 'finished').map(attempt => {
  //   return (
  //     <div key={`attempt-${attempt.id}`}><Button onClick={() => fetchQuizAttempt(attempt)}>{attempt.id}</Button></div>
  //   )
  // })} */}
  // {/* <h4>Finished</h4> */}
  // {/* {attempts && attempts.filter(attempt => attempt.state === 'finished').map(attempt => {
  //   return (
  //     <div key={`attempt-${attempt.id}`}><Button onClick={() => fetchQuizAttempt(attempt)}>{attempt.id}</Button></div>
  //   )
  // })} */}
  // {/* <h4>Complete Attempt</h4> */}