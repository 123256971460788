import { useState } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { useCoursesContext } from '../contexts/CoursesContext';

import UnitComponent from '../components/Unit';

function Unit() {

    const navigate = useNavigate();
    const { courseId, unitId, moduleId } = useParams();
    const { courses : { allUnits }, dispatch } = useCoursesContext()

    if(allUnits.length === 0) {
      return false;
    }

    const currentUnit = allUnits.find(unit => unit.id === parseInt(unitId));

    return (
        <div style={{ flexGrow : 1 }}>
          {!moduleId ? <UnitComponent /> : false}
          <Outlet />
      </div>
    )
}

export default Unit;
