import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCoursesContext, setModuleContent } from '../contexts/CoursesContext';
import { useUserContext } from '../contexts/UserContext';
import { getModuleContent } from '../api/courses';

import Page from '../components/modules/Page';
import Assign from '../components/modules/Assign';
import Forum from '../components/modules/Forum';
import Quiz from '../components/modules/Quiz';
import Lesson from '../components/modules/Lesson';
import { Button } from "react-bootstrap";

function Module() {

  const navigate = useNavigate();
  const { unitId, moduleId, courseId } = useParams();
  const { courses : { allUnits, moduleContent }, dispatch } = useCoursesContext()

  const [currentModule, setCurrentModule] = useState(false)
  const [currentUnit, setCurrentUnit] = useState(false)
  const [currentLessonIndex, setCurrentLessonIndex] = useState(false)
  const [currentModuleIndex, setCurrentModuleIndex] = useState(false)
  const [firstModuleInUnit, setFirstModuleInUnit] = useState(false)
  const [lastModuleInUnit, setLastModuleInUnit] = useState(false)

  useEffect(() => {
    const currentUnitNew = allUnits.find((unit) => unit.id === parseInt(unitId));
    setCurrentUnit(currentUnitNew)
    currentUnitNew.lessons.forEach((lesson, iL) => {
      let firstModuleIndex = false
      lesson.modules.forEach((module, i) => {
        if(!module.noviewlink && firstModuleIndex!== 0 && !firstModuleIndex){
          firstModuleIndex = i
        }
        if(module.id === parseInt(moduleId)) {
          if(i === firstModuleIndex) {
            if(i === lesson.modules.length - 1) {
              //this means its the first and last lesson in module (theres only one lesson)
              setFirstModuleInUnit(true)
              setLastModuleInUnit(true)
            } else {
              setFirstModuleInUnit(true)
              setLastModuleInUnit(false)
            }
          } else if(i === lesson.modules.length - 1) {
            setFirstModuleInUnit(false)
            setLastModuleInUnit(true)
          } else {
            setFirstModuleInUnit(false)
            setLastModuleInUnit(false)
          }
          setCurrentLessonIndex(iL)
          setCurrentModuleIndex(i)
          setCurrentModule(module)
        }
      })
    })
  }, [moduleId, allUnits]);

  const nextButtonClick = () => {
    if(lastModuleInUnit) {
      navigate(`/courses/${courseId}/unit/${unitId}`)
    } else {
      const newModule = currentUnit.lessons[currentLessonIndex].modules[currentModuleIndex + 1]
      navigate(`/courses/${courseId}/unit/${unitId}/${newModule.id}`)
    }
  }

  const backButtonClick = () => {
    const newModule = currentUnit.lessons[currentLessonIndex].modules[currentModuleIndex -1]
    navigate(`/courses/${courseId}/unit/${unitId}/${newModule.id}`)
  }

  if(!currentModule) {
    return false;
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: !firstModuleInUnit ? 'space-between' :'flex-end'}}>
        {!firstModuleInUnit && <Button onClick={backButtonClick} variant="outline-secondary">Back</Button>}
        <Button variant="outline-secondary" disabled={currentModule.completiondata.state !== 1} onClick={nextButtonClick} style={{justifySelf:"flex-end"}}>{lastModuleInUnit ? "Back to unit" : "Next"}</Button>
      </div>
      {currentModule.modname === 'page' ? <Page currentModule={currentModule} /> : false}
      {currentModule.modname === 'assign' ? <Assign currentModule={currentModule} /> : false}
      {currentModule.modname === 'forum' ? <Forum currentModule={currentModule} /> : false}
      {currentModule.modname === 'quiz' ? <Quiz currentModule={currentModule} /> : false}
      {currentModule.modname === 'lesson' ? <Lesson currentModule={currentModule} /> : false}
    </div>
  )
}

export default Module;
