import { useState, useEffect } from "react";
import { useUserContext } from '../../contexts/UserContext';
import { getForumDiscussion, getDiscussionPosts, viewForum, submitResponseToPost, uploadFile, getForumPosts, rateDiscussionPost } from '../../api/modules';
import draftToHtml from 'draftjs-to-html';
import CustomWysiwygButtonInsertFile from './CustomWysiwygButtonInsertFile'
import { Modal, Button, Card, Form, Accordion } from 'react-bootstrap';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../style/TalkingCircle.scss";
import WysiwygEditor from "./WysiwygEditor";

function Forum({ currentModule }) {

    const { user } = useUserContext()
    const [ topLevelDiscussions, setTopLevelDiscussions ] = useState([]);
    const [ currentDiscussion, setCurrentDiscussion ] = useState(false)
    const [ posts, setPosts ] = useState([])
    const [ students, setStudents ] = useState([])
    const [replyEditorsOpened, setReplyEditorsOpened] = useState([]);
    const [teacherEditorOpened, setTeacherEditorOpened] = useState(false);
    // const [cachedRatings, setCachedRatings] = useState({});

    useEffect(() => {
      if(currentModule) {
        fetchModuleContent(currentModule)
      }
    }, [currentModule])

    useEffect(() => {
      if(topLevelDiscussions.length > 0 && isTalkingCircle()) {
        fetchModulePosts(currentModule)
      }
    }, [topLevelDiscussions])

    useEffect(() => {
      if(posts.length > 0) {
        var newStudents =[]
        setCurrentDiscussion(posts.find(thisPost => parseInt(thisPost.id) === topLevelDiscussions[0].id))
      }
    }, [posts])

    const fetchModuleContent = async (mod) => {
        try {
            const discussions = await getForumDiscussion(mod, user.token);
            setTopLevelDiscussions(discussions);
            const viewed = await viewForum(mod, user.token);
        } catch (error) {
            console.log('error fetching top level');
        }
    }

    const fetchModulePosts = async (mod) => {
        try {
            const postData = await getDiscussionPosts(mod, user.token);
            const response  = await getForumPosts(postData.posts[postData.posts.length - 1].id, user.token)
            setPosts([...postData.posts]);
            setStudents([...postData.students]);
        } catch (error) {
            console.log('error fetching discussions');
        }
    }

    const isTalkingCircle = () => {
      if(topLevelDiscussions.length > 0) {
        if(topLevelDiscussions[0].message.indexOf('fnesc-custom-forum-div-circle') > -1) {
          return true;
        }
      }
      return false;
    }

    if(students.length > 0 && isTalkingCircle()) {
      var size = 60 + ((20 - students.length) * 2.5);
      var width = (size / 2) * -1;
      var height = (size / 2) * -1;
      var angle = 0;
      var step = 2 * Math.PI / students.length;
      var radius = 200;
      var angle = -90 * Math.PI / 180;
    }

    const getUsersReplies = (post) => {
      const postReplies = posts.filter(thisPost => parseInt(thisPost.parentid) === parseInt(post.id))
      var users = [];
      students.forEach(student => {
        var studentReplied = postReplies.find(thisPost => parseInt(thisPost.author.id) === parseInt(student.id));
        if(studentReplied) {
          users.push(student);
        }
      })
      return users;
    }

    const toggleEditor = (i) =>{
      if(replyEditorsOpened.includes(i)){
        const newOpenedEditors = replyEditorsOpened.filter(element => element !== i)
        setReplyEditorsOpened(newOpenedEditors)
      }
      else{
        setReplyEditorsOpened([...replyEditorsOpened, i])
      }
    }

    const doRating = async (mod, post, rating) => {
        try {
            const postData = await rateDiscussionPost(mod, post, rating, user.token);
            fetchModuleContent(currentModule)
        } catch (error) {
            console.log('error rating post', error);
        }
    }

    const renderPost = (post, posts, i, parentID) =>{
      let likeCount = post.ratings.filter(rating => rating.rating === '1').length
      let thoughtfulCount = post.ratings.filter(rating => rating.rating === '2').length
      if(post.parentid === parentID) {
        var thisStudent = students.find(student => parseInt(student.id) === parseInt(post.author.id))
        return (
          <Accordion defaultActiveKey={i}>
          <Accordion.Item key={i} eventKey={i}>
            <Accordion.Header>
              {thisStudent.profile ? <img className="accordion-profile-icon" src={`https://www.fnesc.xyz/moodle/pluginfile.php/${thisStudent.profile.contextid}/user/icon/fnesc/${thisStudent.profile.filename}`} /> : <img className="accordion-profile-icon" src={`https://www.fnesc.xyz/moodle/theme/image.php/fnesc/core/1637274224/u/f1`} /> }
              <p style={{color:'black', fontSize:"smaller", fontWeight:'initial'}}>{thisStudent.firstname} {thisStudent.lastname}</p>
              {/* {post.subject} */}
            </Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{__html : post.message }} />
              <div style={{display:'flex', flexDirection:'column'}}>
                {
                  post.attachments.length > 0 && <a href={post.attachments[0].url}>[Attachment - {post.attachments[0].filenameshort}]</a>
                }
              <div style={{display:'flex'}}>
                <Button onClick={()=> toggleEditor(i)} style={{alignSelf:'flex-start', padding: '15px 0px'}} size="sm" variant="light"><i className="ai-message-square"></i> Reply</Button>
                <Button disabled={post.author.id === user.userid} onClick={() => doRating(currentModule, post, 'like')} style={{alignSelf:'flex-start', padding: '15px 0px', marginLeft:15}} size="sm" variant="light">{likeCount} <i className="ai-thumbs-up" style={{color: post.author.id === user.userid ? 'grey' : 'unset'}}></i></Button>
                <Button disabled={post.author.id === user.userid} onClick={() => doRating(currentModule, post, 'thoughtful')} style={{alignSelf:'flex-start', padding: '15px 0px', marginLeft:15}} size="sm" variant="light">{thoughtfulCount} 🤔</Button>

              </div>
              {
                replyEditorsOpened.includes(i) &&
                <WysiwygEditor post={post} fetchModuleContent={fetchModuleContent} currentModule={currentModule} currentDiscussion={currentDiscussion}></WysiwygEditor>

              }
              </div>
              {
                posts.map((childPost, i2) => {
                  return renderPost(childPost, posts, i2, post.id)
                })
              }
            </Accordion.Body>
          </Accordion.Item>
      </Accordion>
        )
      }
    }

    const getCommentStats = (student) => {
        let count = 0;
        let hasResponded = false;
        posts.forEach(post => {
            if (post.author.id === Number(student.id)) {
                count++
                if (post.parentid === topLevelDiscussions[0].id) { hasResponded = true; }
            }
        });

        return { count, hasResponded }
    }

    return (
        <div className="forum-mod">
            <h2>{currentModule.name}</h2>
            {isTalkingCircle() ?
              <div>
                <Accordion activeKey="one">
                  <Accordion.Item key={'one'} eventKey={'one'}>
                  <Accordion.Header>
                  {
                    //not sure what this is for ⌄⌄⌄ checking to see if there are any replies?
                    // getUsersReplies(posts.find(post => post.parentid === null)).map((replier,i) => {
                    // console.log(replier.profile)
                    // if(!replier.profile) return false;
                    // return (
                       <div className="conversation-space">
                          <h3 dangerouslySetInnerHTML={{__html : topLevelDiscussions[0].message}} />
                          <div className="circle-container">
                              <div id="mid-point">
                                {students.map((student, i) => {
                                  const commentData = getCommentStats(student);

                                  var x = Math.round(width + radius * Math.cos(angle));
                                  var y = Math.round(height + radius * Math.sin(angle));
                                  const animName = i % 2 === 0 ? 'float' : 'floatReverse';
                                  const animDur = Math.round(Math.random() * (6 - 4) + 4);
                                  const animation = `${animName} ${animDur}s ease-in-out infinite`;

                                  const avatar = student.picture !== '0' ? `https://www.fnesc.xyz/moodle/pluginfile.php/5/user/icon/fnesc/f1?rev=${student.picture}` : 'none';
                                  const style = {
                                    right : `${x}px`,
                                    top : `${y}px`,
                                    animation : `${animation}`,
                                    backgroundImage : `url(${avatar})`,
                                    height : `${size}px`,
                                    width : `${size}px`
                                  };
                                  angle = angle - step;
                                  return (
                                    <div className="student" style={style} key={`student-${i}`}>
                                      <div className="name"> {student.username} </div>
                                      { commentData.hasResponded ? <i className="ai-check"></i> : false }
                                      <span className="comments">{commentData.count}</span>
                                    </div>
                                  )
                                })}
                              </div>
                              <Button variant="primary" className="topic" onClick={()=> setTeacherEditorOpened(true)}>
                                Share Your Thoughts
                                <i className="ai-message-square"></i>
                              </Button>
                          </div>
                        </div>

                  //   )
                  // })
                  }
                  </Accordion.Header>
                    <Accordion.Body>
                        {posts.length > 0 && students.length > 0 && posts.map((post, i) => {
                           return renderPost(post, posts, i, currentDiscussion.id)
                          })
                        }
                    </Accordion.Body>
                  </Accordion.Item>

                {/* <div style={{marginTop: 100, maxHeight: window.innerHeight/3, overflowY: 'scroll'}} > */}
                  </Accordion>
                {/* </div> */}

              </div>
            :
              <div>
                {topLevelDiscussions.map((discussion) => {
                  return (
                    <div>
                      <h3>{discussion.subject}</h3>
                      <div dangerouslySetInnerHTML={{__html : discussion.message }} />
                    </div>
                  )
                })}
              </div>
            }
            <Modal size="md" show={teacherEditorOpened} onHide={() => setTeacherEditorOpened(false)}>
                <Modal.Body>
                    <h3>Reply</h3>
                    <WysiwygEditor post={currentDiscussion} fetchModuleContent={fetchModuleContent} currentModule={currentModule} currentDiscussion={currentDiscussion} closeModal={setTeacherEditorOpened}></WysiwygEditor>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setTeacherEditorOpened(false)} variant="secondary">Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Forum;
