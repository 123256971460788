import { useEffect } from 'react'
import { useParams, Outlet } from "react-router-dom";

import { useCoursesContext, setCourses, setUnits } from '../contexts/CoursesContext';
import { useUserContext } from '../contexts/UserContext';
import { getCourses, getUnits } from '../api/courses';

import CourseComponent from '../components/Course';

function Course() {
    const { courseId, unitId, moduleId } = useParams();
    const { courses : { allCourses, allUnits }, dispatch } = useCoursesContext()
    const { user } = useUserContext()

    useEffect(() => {
      if(user.userid && user.token) {
        fetchUnits(courseId);
      }
    }, [user]);

    const fetchUnits = async (courseId) => {
      try {
        const units = await getUnits(courseId, user.token);
        dispatch(setUnits(units))
      } catch (error) {
        console.log('error fetching units');
      }
    }

    // Render empty until loading occurs
    if(allCourses.length === 0 || allUnits.length === 0) {
      return false;
    }

    return (
      <div className={"unit-container" + (unitId ? " in-unit" : "") } >
        { !moduleId ?  <CourseComponent /> : false }
        <Outlet />
      </div>
    )
}
// <Routes>
//   <Route path="unit/:unitId" element={<Unit />} />
// </Routes>

export default Course;

/*
{!unitId ?
  <CourseComponent />
: false}
*/

