import { memo, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { Box } from "./Box.js";
import { Dustbin } from "./Dustbin.js";

const isVen = true;

export const DragDrop = memo(function Container({assignmentContent, setOnlineTextValue, onlineTextValue}) {
  const [terms, setTerms] = useState([])
  const [columns, setColumns] = useState([])
  const [isVenn, setIsVenn] = useState(false)

  useEffect(() => {
    if(assignmentContent){
      const s = assignmentContent.intro;
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = s;
      if(htmlObject.getElementsByClassName("fnesc-custom-assignment-div-columns").length > 0){
        const newTerms = htmlObject.getElementsByClassName("fnesc-custom-assignment-div-columns")[0].getAttribute("data-terms")
        const newColumns = htmlObject.getElementsByClassName("fnesc-custom-assignment-div-columns")[0].getAttribute("data-columns")
        const formattedTerms = newTerms.split(", ").map(term => ({ name: term, type: "box" }))
        const formattedColumns = newColumns.split(", ").map(column => ({ accepts: ["box"], lastDroppedItem: null, name:column, items:[] }))
        setTerms(formattedTerms)
        setColumns(formattedColumns)
      }
      else if(htmlObject.getElementsByClassName("fnesc-custom-assignment-div-venn").length > 0){
        // "<div id="id_fnesc-custom-assignment" class="fnesc-custom-assignment-div-venn" data-terms="term 1, term 2, term 3, term 4" data-circles="column 1, column 2"></div>"
        setIsVenn(true)
        const newTerms = htmlObject.getElementsByClassName("fnesc-custom-assignment-div-venn")[0].getAttribute("data-terms")
        const newColumns = htmlObject.getElementsByClassName("fnesc-custom-assignment-div-venn")[0].getAttribute("data-circles")

        let formattedTerms = newTerms.split(", ").map(term => ({ name: term, type: "box" }))
        let formattedColumns = newColumns.split(", ").map(column => ({ accepts: ["box"], lastDroppedItem: null, name:column, items:[] }))

        let addedMiddleColumn = [{ accepts: ["box"], lastDroppedItem: null, name:'Center', items:[] }]
        addedMiddleColumn.push(formattedColumns[1])
        addedMiddleColumn.unshift(formattedColumns[0])

        // Reloading if there's already content
        if(onlineTextValue !== "") {
          let userSubmission = [];
          let splitByLines = onlineTextValue.split('\n');
          splitByLines.forEach(line => {
            if(line !== "") {
              let splitByColon = line.split(': ');
              if(splitByColon[0] && splitByColon[1]) {
                let columnName = splitByColon[0];
                let columnTerms = splitByColon[1].split(', ');
                if(columnTerms[0] !== "(none entered) ") {
                  userSubmission.push({
                    column : columnName,
                    terms : columnTerms
                  })
                }
              }
            }
          })
          userSubmission.forEach(userColumn => {
            let columnIndex = addedMiddleColumn.findIndex(column => column.name === userColumn.column);
            addedMiddleColumn[columnIndex].items = userColumn.terms;
            userColumn.terms.forEach(term => {
              let termIndex = formattedTerms.findIndex(thisTerm => thisTerm ? thisTerm.name === term : false);
              delete formattedTerms[termIndex];
            })
          })
        }

        setTerms(formattedTerms)
        setColumns(addedMiddleColumn)
      }
    }
  }, [assignmentContent])

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  const handleDrop = useCallback((index, item) => {
    const { name } = item;

    const termsClone = JSON.parse(JSON.stringify(terms));
    const updatedTerm = termsClone.map(term => term.name !== name ? term: undefined).filter(childTerm => childTerm !== undefined)
    setTerms(updatedTerm)
    const columnsClone = JSON.parse(JSON.stringify(columns))
    const updatedColumns = columnsClone.map((column, i) => {
      const updatedColumn = column;
      if(i !== index && column.items.includes(name)) {
        updatedColumn.items = updatedColumn.items.map(childItem => childItem !== name ? childItem : undefined).filter(childItem => childItem !== undefined)
      }
      else if(i === index && !column.items.includes(name)) {
        updatedColumn.items.push(name)
      }
      return updatedColumn
    })

    setColumns(updatedColumns)

    let autoText = "";
    updatedColumns.forEach((column, i) => {
      autoText += column.name + ': ';
      if(column.items.length > 0) {
        column.items.forEach((item, i) => {
          autoText += item;
          if(i !== column.items.length - 1) {
            autoText += ', ';
          } else {
            autoText += '\r\n';
          }
        })
      } else {
        autoText += '(none entered) \r\n';
      }
    })
    setOnlineTextValue(autoText)

  }, [droppedBoxNames, columns]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div>


        <div style={{ overflow: "hidden", clear: "both" }}>
          {terms.map(({ name, type }, index) => (
            <Box
              name={name}
              type={type}
              isDropped={isDropped(name)}
              key={index}
            />
          ))}

        </div>

        <div class={isVen ? "vendiagram" :false}>
          {columns.map(({ accepts, lastDroppedItem, name, items }, index) => (
            <Dustbin
              name={name}
              isMiddle={index === columns.length - 2}
              accept={accepts}
              items={items}
              lastDroppedItem={lastDroppedItem}
              onDrop={(item) => handleDrop(index, item)}
              key={index}
              isVen={isVen}

            />
          ))}
        </div>

        <div style={{ overflow: "hidden", clear: "both" }}>
        </div>

      </div>
    </DndProvider>
  );
});
