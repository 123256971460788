import { useEffect, useState } from "react"
import { Badge, Button, Form, Modal, Spinner } from "react-bootstrap"
import { deleteNote, makeUserNote, updateNote } from "../../api/user";
import { useUserContext } from "../../contexts/UserContext";

export const Note = ({fetchNotes, note, moduleId, unitId, courseId}) => {
    const { user } = useUserContext()
    const [noteID, setNoteID] = useState(false)
    const [ content, setContent ] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(note){
            const content = JSON.parse(note.content)
            setContent(content.text)
            setNoteID(note.id)
        }
    }, []);

    const saveNote = async () =>{
        try {
            setLoading(true)
            if(noteID){
                const noteContent = {
                    unit: unitId,
                    module: moduleId,
                    text: content
                }
                const response = await updateNote(JSON.stringify(noteContent), noteID, user.token)
            }
            else {
                const newNoteContent = {
                    unit: unitId,
                    module: moduleId,
                    text: content
                }
                const newNote = await makeUserNote(JSON.stringify(newNoteContent), courseId, user.userid, user.token)
            }

            fetchNotes()
            setLoading(false)

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%'}}>
            {

            }
            <Form.Control as="textarea" style={{height: '50vh'}} placeholder="Note" value={content} onChange={(e) => setContent(e.target.value)}/>
            <Button disabled={loading} style={{marginTop: 10}} onClick={saveNote} variant="primary">{loading? <Spinner animation="border" size="sm" /> :'Save'}</Button>
        </div>
    )
}