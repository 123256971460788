import React, { createContext, useReducer, useContext } from "react";

export const UserContext = createContext();

const initialState = {
  token : null,
  userid : null,
  username : null,
  userfullname : null,
  userfirstname : null,
  userlastname : null,
  userimage : null,
  useremail : null,
  error : null
}

// Actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const MORE_DATA = 'MORE_DATA'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

// Creators
export function loginSuccess(token, user) {
  window.localStorage.setItem('fnesc-user-token', token);
  return { type : LOGIN_SUCCESS, token, user }
}
export function moreDataSuccess(userData) {
  return { type : MORE_DATA, userData }
}
export function loginFail(error) {
  window.localStorage.removeItem('fnesc-user-token');
  return { type : LOGIN_FAIL, error }
}
export function logout() {
  window.localStorage.removeItem('fnesc-user-token');
  return { type : LOGOUT }
}

// Reducer
export function userReducer(state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        token : action.token,
        userid : action.user.userid,
        username : action.user.username,
        userfullname : action.user.fullname,
        userfirstname : action.user.firstname,
        userlastname : action.user.lastname,
        userimage : action.user.userpictureurl,
        error: null
      };
    case MORE_DATA:
      return {
        ...state,
        useremail : action.userData.email
      }
    case LOGIN_FAIL:
      return { token : null, error: action.error };
    case LOGOUT:
      return { token : null, userid : null, username : null };
    default:
      return state;
  }
}

function UserProvider(props) {
  const [user, dispatch] = useReducer(userReducer, initialState);

  const userData = { user, dispatch };

  return <UserContext.Provider value={userData} {...props} />;
}

function useUserContext() {
  return useContext(UserContext);
}

export { UserProvider, useUserContext };
