export const replaceImgAndATagUrl = (htmlString, modContent, token) => {
    //replace href and source for both a tags and image src
    const htmlObject = document.createElement('div');
    htmlObject.innerHTML = htmlString;
    //get all tags
    const nodeElements = htmlObject.getElementsByTagName("*")
    modContent.forEach(file => {
      for (var i = 0; i < nodeElements.length; i++) {
        const aTag = nodeElements[i];
        if(aTag.nodeName === "IMG"){
            // console.log(aTag.src)
            // console.log(encodeURI(file.filename))
        }
        if(aTag.href && aTag.href.includes(encodeURI(file.filename))){
          aTag.href = !file.fileurl.includes('?forcedownload=1') ? `${file.fileurl}?forcedownload=1&token=${token}` : `${file.fileurl}&token=${token}`
        }
        else if(aTag.src && aTag.src.includes(encodeURI(file.filename))){
          aTag.src = !file.fileurl.includes('?forcedownload=1') ? `${file.fileurl}?forcedownload=1&token=${token}` : `${file.fileurl}&token=${token}`

        }
      }
    })
    htmlString = htmlObject.innerHTML

    return htmlString;
}
