// General

import { replaceImgAndATagUrl } from "../utils/URLFormatting";

export async function uploadFile(fileDetails, userid, token) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/fnesc-api.php?wsfunction=file_upload&wstoken=${token}`, {
      method : "POST",
      body : JSON.stringify({
        component : 'user',
        filearea : 'draft',
        itemid : 0,
        filepath : '/',
        filename : fileDetails.name,
        filecontent : fileDetails.file.split(',')[1],
        contextlevel : 'user',
        instanceid : userid
      })
    }).then(resp => resp.json())
  console.log(response)
  if (response) return response
  throw new Error("Error uploading file.")
}

// Assignment
export async function getAssignmentContent(courseId, module, token) {
  var service = 'mod_assign_get_assignments';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?courseids[0]=${courseId}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  const thisCourse = response.courses[0].assignments.find(assignment => assignment.cmid === module.id);

  thisCourse.intro = replaceImgAndATagUrl(thisCourse.intro, thisCourse.introfiles, token)

  if (thisCourse) return thisCourse
  throw new Error("Error fetching assignment content.")
}

export async function viewAssign(mod, token) {
  var service = 'mod_assign_view_assign';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?assignid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error marking assignment as viewed.")
}

export async function getAssignmentSubmissions(mod, userid, token) {
  var service = 'mod_assign_get_submissions';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?assignmentids[0]=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  var userSubmissions = [];

  const thisAssignment = response.assignments.find(assignment => assignment.assignmentid === mod.instance);
  if(thisAssignment) {
    userSubmissions = thisAssignment.submissions.filter(subm => subm.userid === userid);
  }
  if (userSubmissions) return userSubmissions
  throw new Error("Error fetching assignment submissions.")
}

export async function saveUploadSubmission(mod, itemid, token) {
  var service = 'mod_assign_save_submission';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?assignmentid=${mod.instance}&plugindata[files_filemanager]=${itemid}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error saving upload submission.")
}

export async function saveTextSubmission(mod, text, token) {
  var service = 'mod_assign_save_submission';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?assignmentid=${mod.instance}&plugindata[onlinetext_editor][text]=${encodeURIComponent(text)}&plugindata[onlinetext_editor][format]=1&plugindata[onlinetext_editor][itemid]=0&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response

  throw new Error("Error saving text submission.")
}

// Page
export async function getPageContent(mod, token) {
  const htmlContent = mod.contents.find(file => file.filename.includes(".html"))
  let response = await fetch(`${htmlContent.fileurl}&token=${token}`, {
      method : "GET"
    }).then(res => res.text())

    response = replaceImgAndATagUrl(response, mod.contents, token)


  if (response) return response
  throw new Error("Error fetching module content.")
}

export async function viewPage(mod, token) {
  var service = 'mod_page_view_page';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?pageid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error marking page as viewed.")
}

// Lesson
export async function getLessonInfo(courseid, mod, token) {
  var service = 'mod_lesson_get_lessons_by_courses';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?courseids[0]=${courseid}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  const thisLesson = response.lessons.find(quiz => quiz.coursemodule === mod.id);
  if (thisLesson) return thisLesson
  throw new Error("Error fetching lesson info.")
}

export async function viewLesson(mod, token) {
  var service = 'mod_lesson_view_lesson';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?lessonid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error marking lesson as viewed.")
}

export async function getLessonPages(mod, token) {
  var service = 'mod_lesson_get_pages';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?lessonid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response.pages) return response.pages
  throw new Error("Error getting lesson pages.")
}

export async function getPageData(mod, pageid, token) {
  var service = 'mod_lesson_get_page_data';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?lessonid=${mod.instance}&pageid=${pageid}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error getting page data.")
}

// Quiz
export async function getQuizInfo(courseid, mod, token) {
  var service = 'mod_quiz_get_quizzes_by_courses';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?courseids[0]=${courseid}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  const thisQuiz = response.quizzes.find(quiz => quiz.coursemodule === mod.id);
  if (thisQuiz) return thisQuiz
  throw new Error("Error fetching quiz info.")
}

export async function viewQuiz(mod, token) {
  var service = 'mod_quiz_view_quiz';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?quizid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error marking quiz as viewed.")
}

export async function startQuizAttempt(mod, token) {
  var service = 'mod_quiz_start_attempt';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?quizid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response.attempt) return response.attempt
  throw new Error("Error starting quiz attempt.")
}

export async function getQuizAttempts(mod, userid, token) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/fnesc-api.php?quizid=${mod.instance}&userid=${userid}&wstoken=${token}&wsfunction=get_quiz_attempts`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error fetching quiz attempts.")
}

export async function getQuizAttemptPage(attempt, page, token) {
  var service = 'mod_quiz_get_attempt_data';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?attemptid=${attempt.id}&page=${page}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error fetching forum discussion.")
}

export async function saveQuizAttempt(attempt, slot, questions, sequence, token) {
  var service = 'mod_quiz_save_attempt';
  var qs = `&data[0][name]=slot&data[0][value]=${slot}`;
  qs += `&data[1][name]=${sequence.name}&data[1][value]=${sequence.val}`;
  questions.forEach((question, i) => {
    qs += `&data[${i+2}][name]=${question.name}&data[${i+2}][value]=${question.val}`;
  })
  var id = attempt ? attempt.id : false
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?attemptid=${id}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json${qs}`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error saving quiz attempt.")
}

export async function finishQuizAttempt(attempt, token) {
  var service = 'mod_quiz_process_attempt';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?attemptid=${attempt.id}&finishattempt=1&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error finishing quiz attempt.")
}

// Forum
export async function getForumDiscussion(mod, token) {
  var service = 'mod_forum_get_forum_discussions';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?forumid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response.discussions) return response.discussions
  throw new Error("Error fetching forum discussion.")
}

export async function viewForum(mod, token) {
  var service = 'mod_forum_view_forum';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?forumid=${mod.instance}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error marking forum as viewed.")
}

export async function getDiscussionPosts(mod, token) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/fnesc-api.php?moduleid=${mod.id}&wstoken=${token}&wsfunction=get_forum_posts`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error fetching forum discussion.")
}

export async function rateDiscussionPost(mod, post, rating, token) {
  let ratingValue = false;
  if(rating === 'like') {
    ratingValue = 1;
  }
  if(rating === 'thoughtful') {
    ratingValue = 2;
  }
  var service = 'core_rating_add_rating';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?contextlevel=module&instanceid=${mod.id}&component=mod_forum&itemid=${post.id}&scaleid=-6&ratingarea=post&rating=${ratingValue}&aggregation=1&rateduserid=${post.author.id}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if (response) return response
  throw new Error("Error rating forum post.")
}

export async function submitResponseToPost(postID, token, subject, message,imageIDs) {
  const service =  'mod_forum_add_discussion_post'
  //had to create params like this cause i was getting an error for sending html?

  const urlParams = new URLSearchParams({ postid: postID, wstoken:token, wsfunction: service, moodlewsrestformat: 'json', subject: subject, message:message}).toString()

  const attachments = imageIDs.length > 0 ? `&options[0][name]=attachmentsid&options[0][value]=${imageIDs[0]}` : ''
  try {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?${attachments}&${urlParams}`)
    if (response) return response
    throw new Error("Error fetching forum discussion.")

  } catch (error) {
    console.log(error)
  }
}

export async function getForumPosts(postID, token) {
  var service = 'mod_forum_get_discussion_post';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?postid=${parseInt(postID)}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  return response
}
