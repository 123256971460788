import { useUserContext } from '../../contexts/UserContext';

function ProfileSideNav() {

  const { user, dispatch } = useUserContext()
  const logoutClick = () => {
    window.localStorage.clear()
    // return <Navigate to="/courses" />

  }
  return (
    <div className="bg-light rounded-3 shadow-lg">
      <div className="px-4 py-4 mb-1 text-center"><img className="d-block rounded-circle mx-auto my-2" src={user.userimage} at="Amanda Wilson" width="110" />
        <h6 className="mb-0 pt-1">{user.userfullname}</h6><span className="text-muted fs-sm">@{user.username}</span>
      </div>
      {/* <div className="d-lg-none px-4 pb-4 text-center"><a className="btn btn-primary px-5 mb-2" href="#account-menu" data-bs-toggle="collapse"><i className="ai-menu me-2"></i>Account menu</a></div> */}
      <div className="d-lg-block pb-2" id="account-menu">
        <h3 className="d-block bg-secondary fs-sm fw-semibold text-muted mb-0 px-4 py-3">Dashboard</h3>
        <a className="d-flex align-items-center nav-link-style px-4 py-3" href="dashboard-orders.html">
          <i className="ai-shopping-bag fs-lg opacity-60 me-2"></i>Grades
        </a>
        <a className="d-flex align-items-center nav-link-style px-4 py-3" href="/avatar-creator">
          <i className="fas fa-user-edit"></i>Avatar Creator
        </a>
        <h3 className="d-block bg-secondary fs-sm fw-semibold text-muted mb-0 px-4 py-3">Settings</h3>
        <a className="d-flex align-items-center nav-link-style px-4 py-3" href="dashboard-orders.html">
          <i className="ai-shopping-bag fs-lg opacity-60 me-2"></i>General
        </a>
        <a onClick={logoutClick} className="d-flex align-items-center text-danger nav-link-style px-4 py-3 border-top" href="/login"><i className="ai-log-out fs-lg opacity-60 me-2"></i>Sign out</a>
      </div>
    </div>
  );
}

export default ProfileSideNav;
