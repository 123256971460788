import { useEffect } from 'react'
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { useCoursesContext, setCourses, setActive } from '../contexts/CoursesContext';
import { getCourses } from '../api/courses';

import { useUserContext } from '../contexts/UserContext';

import CourseBreadcrumbs from '../components/CourseBreadcrumbs';
import CourseListComponent from '../components/CourseList';
import MyWork from '../components/MyWork';
import Course from './Course';

function Courses() {

  const { courseId, unitId } = useParams();
  const { courses : { allCourses }, dispatch } = useCoursesContext()
  const { user } = useUserContext()

  useEffect(() => {
    let isSubscribed = true;

    if (allCourses.length === 0 && user.userid && user.token) {
      fetchCourses(isSubscribed).catch(error =>  console.log('error fetching courses'));
    }

    return () => isSubscribed = false;

  }, [user])

  const fetchCourses = async (isSubscribed) => {
    const allCourses = await getCourses(user.userid, user.token);
    if(isSubscribed){
      dispatch(setCourses(allCourses))
    }
  }

  const sidebarRender = (children) => {
    return (
      <div className="sidebar-enabled sidebar-end">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 unit-page content py-4 mb-2 mb-sm-0 pb-sm-5" id="course-content">
              {children}
            </div>
            <div className="sidebar col-lg-3 pt-lg-5" style={{minHeight : window.innerHeight - 97}}>
              <MyWork />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const noSidebarRender = (children) => {
    return (
      <div style={{backgroundColor : '#f7f7fc'}}>
        {!courseId && !unitId ?
          <CourseListComponent />
        : false}
        <div className="container">
          <div className="row">
            <div className="content">
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const childrenToRender = (
    <div>
      {courseId && allCourses.length > 0 ?
        <CourseBreadcrumbs />
      : false}

      <Outlet />
    </div>
  )

  if(courseId && unitId) {
    return sidebarRender(childrenToRender);
  } else {
    return noSidebarRender(childrenToRender);
  }
}

export default Courses;
