import { useState } from 'react'
import { Navigate } from "react-router-dom";

import { useUserContext, loginSuccess, loginFail } from '../contexts/UserContext';
import { getToken, getUser } from '../api/user';

function Login() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)
  // Password1!
  //tempranova


  //testuser
  // Hello!123
  const { user, dispatch } = useUserContext()

  const handleLogin = async (e) => {
    try {
      const token = await getToken(username, password)
      const newUser = await getUser(token);
      dispatch(loginSuccess(token, newUser))
    } catch (error) {
      dispatch(loginFail(error))
    }
  }

  const inputChange = (e, callback)=>{
    dispatch(loginFail(false))
    callback(e.target.value)
  }

  if (user.token && user.userid) {
    return <Navigate to="/courses" />
  }

  return (
    <div style={{backgroundColor : '#f7f7fc'}}>
      <section className="container d-flex justify-content-center align-items-center pt-4 pb-4" style={{flex : "1 0 auto"}}>
        <div className="signin-form mt-3">
          <div className="signin-form-inner">
            <div className="view show" id="signin-view">
              <h1 className="h2 text-center">Sign in</h1>
              <p className="fs-ms text-muted mb-4 text-center">Sign in to your account using the username and password you were given.</p>
              {user.error && <div className="alert alert-danger" role="alert">Invalid username and/or password</div>}
              <div className="needs-validation" noValidate>
                <div className="input-group mb-3"><i className="ai-user position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                  <input className="form-control rounded" placeholder="Username" required value={username} onChange={e => inputChange(e, setUsername)} />
                </div>
                <div className="input-group mb-3"><i className="ai-lock position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                  <div className="password-toggle w-100">
                    <input className="form-control" type={passwordIsVisible? "text" : "password" } placeholder="Password" onChange={e => inputChange(e, setPassword)} required value={password} />
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <span  onClick={() => setPasswordIsVisible(!passwordIsVisible)}  className={passwordIsVisible? 'ai-eye' : 'ai-eye-off'}></span>
                      {/* <input className="password-toggle-check" onChange={() => setPasswordIsVisible(!passwordIsVisible)} type="checkbox"  /><span className="password-toggle-indicator"></span> */}
                    </label>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center mb-3 pb-1">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="keep-signed-2" />
                    <label className="form-check-label" htmlFor="keep-signed-2">Keep me signed in</label>
                  </div><a className="nav-link-style fs-ms" href="password-recovery">Forgot password?</a>
                </div> */ }
                <button className="btn btn-primary d-block w-100" onClick={handleLogin}>Sign in</button>
                {/* <p className="fs-sm pt-3 mb-0 text-center">Don't have an account? <a href='#' className='fw-medium' data-view='#signup-view'>Sign up</a></p> */}
              </div>
            </div>
            {/* <div className="view" id="signup-view">
              <h1 className="h2 text-center">Sign up</h1>
              <p className="fs-ms text-muted mb-4 text-center">Registration takes less than a minute but gives you full control over your orders.</p>
              <form className="needs-validation" noValidate>
                <div className="mb-3">
                  <input className="form-control" type="text" placeholder="Full name" required />
                </div>
                <div className="mb-3">
                  <input className="form-control" type="text" placeholder="Email" required />
                </div>
                <div className="input-group mb-3">
                  <div className="password-toggle w-100">
                    <input className="form-control" type={"password" }placeholder="Password" required />
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="password-toggle w-100">
                    <input className="form-control" type="password" placeholder="Confirm password" required />
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <button className="btn btn-primary d-block w-100" type="submit">Sign up</button>
                <p className="fs-sm pt-3 mb-0 text-center">Already have an account? <a href='#' className='fw-medium' data-view='#signin-view'>Sign in</a></p>
              </form>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
// <div>
//   <input type="text" onChange={setUsername} value={username} />
//   <input type="password" onChange={setPassword} value={password} />
//   <button onClick={handleLogin}>Login</button>
// </div>

export default Login;
