import { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap"

export default function CustomWysiwygButtonInsertFile({currentFiles, setCurrentFiles}){

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
      const fileChange = (e) => {
        let file = e.target.files[0];
        getBase64(file, (result) => {
            setCurrentFiles( [{ file : result, name : file.name, type:file.type }]);
        });
    }
    return (
        <Dropdown>
            <Dropdown.Toggle  style={{padding: '0px 10px', fontSize: 'small'}} id="dropdown-basic">
                Add attachment
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Form.Control size='sm' onChange={(e) => fileChange(e)} type="file"  />
            </Dropdown.Menu>
        </Dropdown>
    )
}