import Avatar, {allOptions} from 'avataaars'
import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useUserContext } from '../contexts/UserContext';
import { avatarOptions } from './Constants';
import * as ReactDOM from 'react-dom'
import * as FileSaver from 'file-saver'

function AvatarCreator() {
  const canvasRef = useRef()
  const avatarRef = useRef()
  const { user, dispatch } = useUserContext()
  const [ avatar, setAvatar ] = useState({
    avatarStyle: 'Circle',
    topType: 'LongHairMiaWallace',
    accessoriesType: 'Prescription02',
    hatColor: 'Blank',
    hairColor:'Black',
    facialHairType:'Blank',
    facialHairColor:'Blank',
    clotheType:'Hoodie',
    clotheColor:'Red',
    graphicType:'Blank',
    eyeType:'Happy',
    eyebrowType:'RaiseExcitedNatural',
    mouthType:'Default',
    skinColor:'Light'
  })
  const updateAvatar = (key, value) => {
    const avatarClone = JSON.parse(JSON.stringify(avatar))
    avatarClone[key] = value
    setAvatar(avatarClone)
  }
  const downloadAvatar = () => {
    const svgNode = ReactDOM.findDOMNode(avatarRef.current)
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const anyWindow = window
    const DOMURL = anyWindow.URL || anyWindow.webkitURL || window

    const data = svgNode.outerHTML
    const img = new Image()
    const svg = new Blob([data], { type: 'image/svg+xml' })
    const url = DOMURL.createObjectURL(svg)

    img.onload = () => {
      ctx.save()
      ctx.scale(2, 2)
      ctx.drawImage(img, 0, 0)
      ctx.restore()
      DOMURL.revokeObjectURL(url)
      canvasRef.current.toBlob((imageBlob) => {
          FileSaver.saveAs(imageBlob, 'avataaars.png')
      })
    }
    img.src = url
  }

  return (
    <div className="d-flex flex-column h-100 bg-light rounded-3 shadow-lg p-4">
      <div className="py-2 p-md-3">
        <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
          <h1 className="h3 mb-2 text-nowrap">Create Avatar</h1>
        </div>
        <div className="bg-secondary rounded-3 p-4 mb-4">
          <div className="d-block d-sm-flex align-items-center">
            <div className="ps-sm-3 text-center text-sm-start" style={{width: '100%'}}>
              <Avatar
                ref={avatarRef}
                style={{width: '100px', height: '100px'}}
                avatarStyle={avatar.avatarStyle}
                topType={avatar.topType}
                accessoriesType={avatar.accessoriesType}
                hairColor={avatar.hairColor}
                facialHairType={avatar.facialHairType}
                clotheType={avatar.clotheType}
                clotheColor={avatar.clotheColor}
                eyeType={avatar.eyeType}
                eyebrowType={avatar.eyebrowType}
                mouthType={avatar.mouthType}
                skinColor={avatar.skinColor}
                hatColor={avatar.hatColor}
                facialHairColor={avatar.facialHairColor}
                graphicType={avatar.graphicType}
              />

                  <div style={{width: '100%'}}>
                    {
                      Object.keys(avatarOptions).map((key,i)=>{
                          return (
                            <>
                            <label>{allOptions.find(e => e.key === key).label}</label>
                            <Form.Select style={{marginTop: 5, marginBottom: 5}} value={avatar[key]} onChange={(e) => updateAvatar(key, e.target.value)} aria-label="Default select example">
                              {
                                avatarOptions[key].options.map(option =>{
                                  return (
                                    <option>{option}</option>
                                  )
                                })
                              }
                            </Form.Select>
                            </>
                          )   
                      })
                    }
                  </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="mt-2 mb-4" />
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <button onClick={downloadAvatar} className="btn btn-primary mt-3 mt-sm-0" type="button"><i className="ai-save fs-lg me-2"></i>Download avatar</button>
            </div>
          </div>
        </div>
      </div>
        <canvas
          style={{ display: 'none' }}
          width='528'
          height='560'
          ref={canvasRef}
        />
    </div>
  );
}

export default AvatarCreator;
