export const replaceImageLinks = (html, token) => {
  let newHTML;
  newHTML = html.replace(/.png/, '.png?token='+token);
  newHTML = newHTML.replace(/.PNG/, '.PNG?token='+token);
  newHTML = newHTML.replace(/.jpg/, '.jpg?token='+token);
  newHTML = newHTML.replace(/.JPG/, '.JPG?token='+token);
  newHTML = newHTML.replace(/.jpeg/, '.jpeg?token='+token);
  newHTML = newHTML.replace(/.JPEG/, '.JPEG?token='+token);
  newHTML = newHTML.replace(/.svg/, '.svg?token='+token);
  newHTML = newHTML.replace(/.SVG/, '.SVG?token='+token);
  return newHTML;
}
