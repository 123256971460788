import React from "react";
import {Document, Page, Text, View} from "@react-pdf/renderer";

const MyWorkPdf = ({currentUnit, studentNotes, courseId,  getWork, courses, user}) => {

    return (
        <Document>
            <Page size="A4"  style={{padding:30}} >
                <Text style={{textAlign:'center', color:'#766df4', fontSize: 30, fontWeight:'bold'}}>
                    My Work
                </Text>
                
                <View style={{alignItems:"center", paddingTop:10}}>
                    <View>
                    <Text style={{fontSize:12, color:'#4a4b65',}}>
                        Course: {courses.allCourses.find(e => e.id.toString() === courseId).fullname}
                    </Text>
                    <Text style={{fontSize:12, color:'#4a4b65',}}>
                        Unit: {currentUnit.name}
                    </Text>
                    <Text style={{fontSize:12,color:'#4a4b65',}}>
                        Date: {new Date().toISOString().split('T')[0]}
                    </Text>
                    </View>
                </View>
                
                <View style={{paddingTop: 40}}>

                {
                    currentUnit.lessons.map((lesson, i) => {
                    return (

                        <View key={i}>
                            <Text style={{color:"#766df4"}}>
                                {lesson.name}
                            </Text>
                            {
                                lesson.modules.filter(mod => mod.modname!=='label').map((mod, i2) => {
                                    return ( <View style={{marginTop:10}} key={i2}>
                                        <View style={{flexDirection:'row', marginLeft:30}}>
                                            <View style={{backgroundColor:'black', alignSelf:'center', height:5, width:5, borderRadius:10}}></View>
                                                <Text  style={{fontSize: 15, marginLeft:10, color:'#4a4b65'}}> 
                                                    {mod.name} 
                                                </Text>
                                            </View>
                                            <View>
                                                {

                                                    getWork(mod).filter(submission => submission.userid === user.userid).length > 0 ? 
                                                        <Text style={{fontSize: 12, marginLeft:70, marginTop:5, color:'#4a4b65'}}>{mod.modname === 'forum' ? "Posts:" : 'Submissions:'}</Text> 
                                                        : 
                                                        <Text style={{fontSize: 12, marginLeft:70, marginTop:5, color:'#4a4b65'}}>Nothing submitted yet</Text>
                                                }

                                                {
                                                    getWork(mod).filter(submission => submission.userid === user.userid).map((results, i3) => {
                                                        if(results.plugins && results.plugins.length > 0 && results.plugins[0].type !== "onlinetext" && results.plugins[0].fileareas && results.plugins[0].fileareas.length > 0 && results.plugins[0].fileareas[0].files && results.plugins[0].fileareas[0].files.length > 0){
                                                            return results.plugins[0].fileareas[0].files.map( (file,i7) => file.filename.length > 0 ? 
                                                                <View  key={i3} style={{flexDirection:'row', marginLeft:80}}>
                                                                    <View style={{backgroundColor:'black', alignSelf:'center', height:5, width:5, borderRadius:10}}></View>
                                                                
                                                                    <Text style={{fontSize: 12, marginLeft:10, color:'#4a4b65'}} ><a  href={file.fileurl + "?token=" + user.token} target={"_blank"} style={{textDecoration:'underline'}}>{file.filename} landon</a></Text>
                                                                </View>
                                                                :false
                                                            )
                                                        }
                                                        else if(results.plugins[0].editorfields){
                                                            return (
                                                                <View style={{flexDirection:'row', marginLeft:80}} key={i3}>
                                                                    <View style={{backgroundColor:'black', alignSelf:'center', height:5, width:5, borderRadius:10}}></View>
                                                                
                                                                    <Text style={{fontSize: 12, marginLeft:10, color:'#4a4b65'}}><p dangerouslySetInnerHTML={{__html:  results.message ? results.message: ""}}> {results.message ? "":results.plugins[0].editorfields[0].text }</p></Text>
                                                                </View>
                                                            )
                                                        }
                                                    })
                                                }

                                            </View>
                                                {
                                                    studentNotes.filter(note => parseInt(JSON.parse(note.content).module) === parseInt(mod.id)).length > 0 ? 
                                                       <View>
                                                            <Text style={{fontSize: 12, marginLeft:70, marginTop:5, color:'#4a4b65'}}>
                                                                Notes:
                                                            </Text>
                                                            <Text style={{fontSize: 12, marginLeft:80, marginTop:5, color:'#4a4b65'}}>
                                                                {JSON.parse(studentNotes.filter(note => parseInt(JSON.parse(note.content).module) === parseInt(mod.id))[0].content).text}
                                                            </Text>

                                                       </View> 
                                                        :
                                                    false
                                                }
                                        </View>)
                                })
                            }
                            
                        </View>
                    )})
                }
                </View>
            </Page>
        </Document>
    )
}

export default MyWorkPdf;