import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import { makeUserNote } from "../../api/user";

export const AddNote = ({closeModal, data, fetchNotes, courseId, user, currentModule}) => {
    const [ noteContent, setNoteContent ] = useState('');
    const [ noteTitle, setNoteTitle ] = useState('')

    const saveNote = async () => {
        try {
            var makeNote = {
              unit : data.currentUnit.id,
              modname : currentModule ? currentModule.modname : null,
              instance : currentModule ? currentModule.instance : null,
              content : noteContent,
              title : noteTitle
            }
            const newNote = await makeUserNote(JSON.stringify(makeNote), courseId, user.userid, user.token)
            fetchNotes()
            closeModal()
            
        } catch (error) {
            console.log(error)
        }
    }
  

    return (
        <>
            <Modal.Body>
                {data.currentModule ? <p>Module {data.currentModule.name}</p> : false}
                <Form.Control placeholder="Note" as="textarea" style={{height: 300}} onChange={(e) => setNoteContent(e.target.value)}/>
            </Modal.Body>
        </>
    )
}