import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from '../../contexts/UserContext';
import { getAssignmentContent, viewAssign, getAssignmentSubmissions, uploadFile, saveUploadSubmission, saveTextSubmission } from '../../api/modules';
import { Badge,Button, Form, Modal, Spinner } from "react-bootstrap";
import { getUnits } from "../../api/courses";
import { setUnits, useCoursesContext } from "../../contexts/CoursesContext";
import { DragDrop } from "../DragDropExample/DragDrop";

function Assign({ currentModule }) {

    const { courseId } = useParams();
    const { user } = useUserContext()
    const { courses : { allUnits }, dispatch } = useCoursesContext()

    const [ assignmentContent, setAssignmentContent ] = useState(null)
    const [ postSubmissionMessage, setPostSubmissionMessage ] = useState(null);
    const [ assignmentSubmissions, setAssignmentSubmissions ] = useState(null)
    const [ currentFile, setCurrentFile ] = useState(false);
    const [ openChangeSubModal, setOpenChangeSubModal ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ onlineTextValue, setOnlineTextValue ] = useState('')
    const [ changeSub, setChangeSub ] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true);


    useEffect(() => {
      if(currentModule) {
        fetchModuleContent(currentModule)
      }
    }, [currentModule])

    useEffect(() => {
      if(!loadingPage && assignmentContent && isPDFAssignment() && assignmentContent.introattachments.length > 0) {
        var adobeDCView = new window.AdobeDC.View({clientId: "ca3e0d2c2cbf4958bfa9070b9c3e2877", divId: "adobe-dc-view"});

        adobeDCView.previewFile({
          content:{location: {url: `${assignmentContent.introattachments[0].fileurl}?token=${user.token}` }},
          metaData:{fileName: assignmentContent.introattachments[0].filename }
        }, {});

      }
    }, [assignmentContent, loadingPage])

    useEffect(() => {
      if(assignmentSubmissions){
        if(assignmentSubmissions[0] && assignmentSubmissions[0].plugins.find(plugin => plugin.type === 'onlinetext' && assignmentSubmissions[0].status === "submitted")){
          var tempDivElement = document.createElement("div");
          // Set the HTML content with the given value
          tempDivElement.innerHTML = assignmentSubmissions[0].plugins[0].editorfields[0].text;
          setOnlineTextValue(tempDivElement.innerText)
        } else {
          setOnlineTextValue("")
        }
      }
    }, [assignmentSubmissions]);

    const fetchModuleContent = async (mod) => {
        try {
            setLoadingPage(true);
            const viewed = await viewAssign(mod, user.token);
            let modContent = await getAssignmentContent(courseId, mod, user.token);
            const modSubmissions = await getAssignmentSubmissions(mod, user.userid, user.token);
            // Modify content if AFTER_SUBMISSION is present
            if(modContent.intro.indexOf("AFTER_SUBMISSION") > -1) {
              const beforeSubmission = modContent.intro.split("AFTER_SUBMISSION")[0];
              const afterSubmission = modContent.intro.split("AFTER_SUBMISSION")[1];
              modContent.intro = beforeSubmission;
              modContent.postSubmissionMessage = afterSubmission;
            }

            setAssignmentContent(modContent);

            setAssignmentSubmissions(modSubmissions)
            if(modSubmissions.length === 0){
              setChangeSub(true)

            }
            else {
              setChangeSub(false)
            }
            setLoading(false)
            setLoadingPage(false);

        } catch (error) {
          setLoading(false)
            console.log('error fetching page');
        }
        setOpenChangeSubModal(false)
        setLoading(false)
    }

    const isPDFAssignment = () => {
      if(assignmentContent) {
        if(assignmentContent.intro.indexOf('fnesc-custom-assignment-div-pdf') > -1) {
          return true;
        }
      }
      return false;
    }

    const isColumnAssignment = () => {
      if(assignmentContent) {
        if(assignmentContent.intro.indexOf('fnesc-custom-assignment-div-columns') > -1) {
          return true;
        }
        else if(assignmentContent.intro.indexOf('fnesc-custom-assignment-div-venn') > -1){
          return true;
        }
      }
      return false;
    }

    const fileChange = (e) => {
        let file = e.target.files[0];
        getBase64(file, (result) => {
             setCurrentFile({ file : result, name : file.name });
        });
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const upload = async () => {
      try {
        const uploadedFile = await uploadFile(currentFile, user.userid, user.token)
        const savedFile = await saveUploadSubmission(currentModule, uploadedFile.itemid, user.token)
        fetchModuleContent(currentModule)

        const units = await getUnits(courseId, user.token);
        dispatch(setUnits(units))
      } catch (err) {
          console.log('error uploading file', err);
      }
    }

    const uploadText = async () => {
      try {
        setLoading(true)
        const savedText = await saveTextSubmission(currentModule, onlineTextValue, user.token)
        fetchModuleContent(currentModule)
      } catch (err) {
          console.log('error uploading text');
      }
    }

    const handleSubmitClick = async () => {
      if(assignmentContent.configs[0].plugin !== "onlinetext"){
        setOpenChangeSubModal(true)
      }
      else {
        if(changeSub){
          uploadText().then(async resp => {
            const units = await getUnits(courseId, user.token);
            dispatch(setUnits(units))
          })
        }
        else{
          setChangeSub(true)
        }
      }
    }

    return (
        <div>
          <Modal size="sm" show={openChangeSubModal}>
            <Modal.Header><h4>Upload a new file</h4></Modal.Header>
              <Modal.Body>
              <Form.Control onChange={(e) => fileChange(e)} type="file" />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={()=> setOpenChangeSubModal(false)} disabled={loading}>Cancel</Button>
                <Button onClick={() => {upload(); setLoading(true)}} disabled={loading}>{loading? <Spinner animation="border" size="sm" /> :'Upload'}</Button>

              </Modal.Footer>
          </Modal>
            {
              !loadingPage ?
            <>
              <div style={{display:"flex", alignItems:"center", justifyContent:'space-between'}}>
                <h2>{currentModule.name}</h2>
                {
                  assignmentSubmissions && assignmentSubmissions.length > 0 ? assignmentSubmissions.map((subm, i) => (
                    subm.status === 'submitted'  ?
                    <Badge key={`badge-${i}`} bg="success">Submitted</Badge>
                    :
                    <Badge key={`badge-${i}`} bg="secondary">Not Submitted</Badge>
                  )) : <Badge bg="secondary">Not Submitted</Badge>
                }
              </div>

              {assignmentContent ?
                <div>
                  <div dangerouslySetInnerHTML={{__html : assignmentContent.intro }}></div>
                  {isPDFAssignment() ? <div id="adobe-dc-view" style={{height: 500}}>{console.log("here now")}</div> : false}
                </div>
              : false}

              { isColumnAssignment() ?	<DragDrop assignmentContent={assignmentContent} onlineTextValue={onlineTextValue} setOnlineTextValue={setOnlineTextValue}></DragDrop>: false }

              <div>
                {assignmentSubmissions ?
                  <div style={{display:"flex", flexDirection:"column"}}>
                    {
                      assignmentSubmissions.map((subm, i) => {
                        if(subm.plugins.find(plugin => plugin.type === 'file')) {
                          var submissionFiles = subm.plugins.find(plugin => plugin.type === 'file').fileareas[0];
                          return (
                            <div key={`files-${i}`}>
                              {/* <p>Status: {subm.status}</p> */}

                              {submissionFiles.files.map((file, i2) => {
                                return (
                                  <div key={`files-${i2}`}>
                                    {/* <iframe src={file.fileurl+ '#toolbar=0'+ '?token=' + user.token } height="200" width="300"></iframe> */}
                                    {
                                      file.mimetype && file.mimetype.includes("image") ?
                                      <img style={{ margin: '10px'}} src={file.fileurl + '?token=' + user.token}></img>
                                      :
                                      <p style={{ margin: '10px'}}><a href={file.fileurl + '?token=' + user.token}>[Submitted file - {file.filename}]</a></p>
                                    }
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }
                      })
                    }
                    {/* <p>Change Submission</p> */}
                    {assignmentContent.configs[0].plugin === "onlinetext" &&
                      <>
                          {
                            assignmentSubmissions && assignmentSubmissions.length > 0 ? assignmentSubmissions.map((subm, i) => (
                              subm.status === 'submitted' && !changeSub  ?
                                        <div key={`text-${i}`}>
                                          <h4 style={{textAlign:"center"}}>Submission Successful</h4>
                                          <div  dangerouslySetInnerHTML={{__html : assignmentContent.postSubmissionMessage}} ></div>
                                        </div>
                                        : <div key={`text-${i}`}></div>
                                        )): <div></div>
                                      }
                        <div style={{marginBottom: 20}}>
                            <textarea disabled={assignmentSubmissions.length > 0 &&!changeSub} className="form-control" style={{height: 200}} value={onlineTextValue} onChange={(e) => setOnlineTextValue(e.target.value)} placeholder="Write your response here."></textarea>
                          </div>

                      </>
                    }
                    <Button style={{alignSelf:'flex-end'}} disabled={loading} onClick={()=> handleSubmitClick()}>{loading? <Spinner animation="border" size="sm" /> :  !changeSub ? "Change Submission": "Submit Assignment"}</Button>
                  </div>
                :  <div style={{marginBottom: 20}}>
                    <textarea className="form-control" style={{height: 200}} placeholder="Write your response here."></textarea>
                    <Button style={{alignSelf: 'flex-end'}} disabled={loading} onClick={()=> uploadText()}> {loading? <Spinner animation="border" size="sm" /> : 'Submit'}</Button>
                  </div>
                }
              </div>
            </>
            : <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <Spinner animation="border" size="lg" />
              </div>
          }
        </div>
    )
}

export default Assign;
