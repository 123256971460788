import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap"
import { submitResponseToPost, uploadFile } from "../../api/modules";
import { useUserContext } from "../../contexts/UserContext";
import CustomWysiwygButtonInsertFile from "./CustomWysiwygButtonInsertFile";
import { useNavigate, useParams } from "react-router-dom";
import { getUnits } from "../../api/courses";
import { setUnits, useCoursesContext } from "../../contexts/CoursesContext";

export default function WysiwygEditor({post, fetchModuleContent, currentModule, currentDiscussion, closeModal = false}){
    const [ response, setResponse ] = useState(EditorState.createEmpty());
    const navigate = useNavigate();
    const { unitId, moduleId, courseId } = useParams();
    const { courses : { allUnits }, dispatch } = useCoursesContext()

    const [ currentFiles, setCurrentFiles ] = useState(false);

    const [ responseImage, setResponseImage ] = useState(false);
    const { user } = useUserContext()

    const uploadImage = async () => {
        const uploadedFile = await uploadFile(currentFiles, user.userid, user.token)
        setResponseImage(uploadedFile.itemid)
      }

    const sendPost = async (postId) => {
        const fileIds = []
        for (let index = 0; index < currentFiles.length; index++) {
          const uploadedFile = await uploadFile(currentFiles[index], user.userid, user.token)
          fileIds.push(uploadedFile.itemid)
        }
        const responseInHTML = draftToHtml(convertToRaw(response.getCurrentContent()))
        setResponse(EditorState.createEmpty())
        const subject = currentDiscussion.parentid === null ? currentDiscussion.message.split('<')[1].split('>')[1] : currentDiscussion.message
        const reply = await submitResponseToPost(postId, user.token, "Re: " + subject, `${responseInHTML}`, fileIds )
        fetchModuleContent(currentModule)
        const units = await getUnits(courseId, user.token);
        dispatch(setUnits(units))
        closeModal && closeModal(false);
      }

    //   const sendPost = async (postid) => {
    //     const fileIds = []
    //     for (let index = 0; index < currentFiles.length; index++) {
    //       const uploadedFile = await uploadFile(currentFiles[index], user.userid, user.token)
    //       fileIds.push(uploadedFile.itemid)
    //     }
  
    //     const responseInHTML = draftToHtml(convertToRaw(response.getCurrentContent()))
    //     setResponse(EditorState.createEmpty())
    //     const subject = currentDiscussion.parentid === null ? currentDiscussion.message.split('<')[1].split('>')[1] : currentDiscussion.message
    //     console.log(currentDiscussion)
    //     const reply = await submitResponseToPost(postid, user.token, "Re: " + subject, `${responseInHTML}`, fileIds )
    //     // console.log(JSON.pa(reply))
    //     fetchModuleContent(currentModule)
    //   }
    return (
        <div>
            <Editor
                editorState={response}
                onEditorStateChange={e =>  setResponse(e)}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="form-control wysiwyg-text-area"
                placeholder="Type your response here."
                toolbarCustomButtons={[<CustomWysiwygButtonInsertFile setCurrentFiles={setCurrentFiles} currentFiles={currentFiles}/>]}
                toolbar={{options:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history']}}
            />
            { currentFiles && currentFiles.map(file => {
                return file.type.includes("image")? <img height={100} width={100} src={file ? file.file: ''}></img>: <iframe src={file.file}></iframe>
                })
            }
            <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', marginTop: '10px', marginBottom:'10px'}}>
                <Button onClick={() => sendPost(post.id)} variant="primary" size="sm"><i className="ai-send"></i> Post</Button>
            </div>

        </div>
    )
}
