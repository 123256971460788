import { useNavigate } from "react-router-dom";
import { useCoursesContext } from '../contexts/CoursesContext';
import { useUserContext } from '../contexts/UserContext';

function CourseListComponent() {
  const navigate = useNavigate();
  const { courses : { allCourses } } = useCoursesContext()
  const { user } = useUserContext()

  return (
    <div>
      <section className="d-flex align-items-center position-relative bg-dark bg-size-cover bg-position-center overflow-hidden pt-6 pb-lg-5" style={{height: window.innerWidth >= 768 ? 300 : 'auto', backgroundImage: "url(img/demo/presentation/intro/bg.jpg)"}}>
        <div className="container-fluid pt-4 pb-5 py-lg-5">
          <div className="row align-items-center py-3">
            <div className="col-xl-6 col-lg-5 d-flex justify-content-end">
              <div className="pt-2 mx-auto mb-5 mb-lg-0 ms-lg-0 me-xl-7 text-center text-lg-start" style={{maxWidth: '495px'}}>
                <h1 className="display-4 text-light pb-2">
                  {user.userfullname ? <><span className="fw-light">Welcome home, </span>{user.userfullname}!</>: <><span className="fw-light">Please sign in to view your courses.</span></>}
                </h1>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="parallax ms-lg-n5" style={{maxWidth : 920}}>
                <div className="parallax-layer position-relative" data-depth="0.1"><img src="img/demo/presentation/intro/layer01.png" alt="Layer" /></div>
                <div className="parallax-layer" style={{zIndex: "2"}} data-depth="0.3"><img src="img/demo/presentation/intro/layer02.png" alt="Layer" /></div>
                <div className="parallax-layer" data-depth="0.2"><img src="img/demo/presentation/intro/layer03.png" alt="Layer" /></div>
                <div className="parallax-layer" style={{zIndex: "3"}} data-depth="0.1"><img src="img/demo/presentation/intro/layer04.png" alt="Layer" /></div>
                <div className="parallax-layer" data-depth="0.15"><img src="img/demo/presentation/intro/layer05.png" alt="Layer" /></div>
                <div className="parallax-layer" style={{zIndex: "4"}} data-depth="0.25"><img src="img/demo/presentation/intro/layer06.png" alt="Layer" /></div>
                <div className="parallax-layer" data-depth="0.3"><img src="img/demo/presentation/intro/layer07.png" alt="Layer" /></div>
                <div className="parallax-layer" data-depth="0.4"><img src="img/demo/presentation/intro/layer08.png" alt="Layer" /></div>
                <div className="parallax-layer" data-depth="0.35"><img src="img/demo/presentation/intro/layer09.png" alt="Layer" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container overflow-hidden py-5 py-md-6 py-lg-7">
        <div className="masonry-filterable pt-3 pt-md-0">
          <div className="masonry-grid" data-columns="3">
            { allCourses.map((course, i) => {
                return (
                  <div key={`key-${course.id}`} className="masonry-grid-item" data-groups="[&quot;web&quot;]">
                    <a className="card card-hover border-0 shadow" href="#" onClick={() => navigate(`/courses/${course.id}`)}>
                      <img className="card-img-top" src={course.overviewfiles.length > 0 ? course.overviewfiles[0].fileurl + "?token=" + user.token : "img/portfolio/01.jpg"} alt="Portfolio thumb" />
                      <div className="card-body text-center">
                        <h3 className="h5 nav-heading mb-2">{course.fullname} </h3>
                        <div className="fs-sm text-muted mb-2" dangerouslySetInnerHTML={{__html : course.summary.split('.')[0] + '.'}}></div>
                      </div>
                    </a>
                  </div>
                )
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default CourseListComponent;
