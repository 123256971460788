import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useCoursesContext } from '../contexts/CoursesContext';
import { useUserContext } from '../contexts/UserContext';
import { getUserWork, getUserNotes } from '../api/user'
import { Modal, Accordion } from 'react-bootstrap';
import "../style/MyWork.scss";
import { AddNote } from './MyWorkModal/AddNote';
import { Post } from './MyWorkModal/Post';
import { Note } from './MyWorkModal/Note';

import MyWorkPdf from './MyWorkPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

function MyWork() {
  const { courseId, unitId, moduleId } = useParams();
  const { courses, dispatch } = useCoursesContext()
  const { user } = useUserContext()
  const [ myWorkActive, setMyWorkActive] = useState(window.innerWidth >= 768 ? true : false);
  const [ studentWork, setStudentWork ] = useState(null);
  const [ studentNotes, setStudentNotes ] = useState([])
  const [ modalData, setModalData ] = useState(false)
  const [ panelWidth, setPanelWidth ] = useState(0);


  const currentUnit = courses.allUnits.find(unit => unit.id === parseInt(unitId));


  const getPanelWidth = () => {
      if (window.innerWidth > 992) {
          let contentEl = document.getElementById("course-content");
          contentEl && setPanelWidth(document.body.clientWidth - contentEl.getBoundingClientRect().right - 20);
      } else {
        setPanelWidth("auto");
      }
  }
  window.addEventListener("resize", getPanelWidth);

  useEffect(() => {
    const timeout = setTimeout(() => {
        getPanelWidth();
    }, 550);


    return () => {
      if(timeout){
        clearTimeout(timeout)
      }
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if(user.token) {
      fetchWork(isSubscribed).catch(error => console.log("Couldnt fetch work " + error));
      fetchNotes(isSubscribed).catch(error => console.log("Couldnt fetch notes " + error));
    }

    return () => isSubscribed = false;

  }, [user])

  const fetchWork = async (isSubscribed) => {
    const coursework = await getUserWork(courseId, user.userid, user.token);
    if(isSubscribed){
      setStudentWork(coursework)
    }
  }

  const fetchNotes = async (isSubscribed) => {
    const notes = await getUserNotes(courseId, user.userid, user.token);
    // Remove non-JSON ones
    const jsonNotes = notes.filter(note => isJsonString(note.content))
    if(isSubscribed){
      setStudentNotes(jsonNotes)
    }
  }

  const isJsonString = (str) => {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }

  const getWork = (mod) => {
    if(mod.modname === 'assign' && studentWork.assignments) {
      const studentSubmissions = studentWork.assignments.find(assignment => mod.instance === assignment.assignmentid)
      return studentSubmissions ? studentSubmissions.submissions : [];
    }
    if(mod.modname === 'forum' && studentWork.forums.posts) {
      const thisForum = studentWork.forums.discussions.find(discussion => parseInt(discussion.forum) === mod.instance)
      const studentPosts = thisForum? studentWork.forums.posts.filter(post => post.discussion === thisForum.id && parseInt(post.userid) === user.userid && !post.message.includes("dir=\"ltr\"")): [];

      return studentPosts;

    }
    if(mod.modname === 'quiz') {
      return []
    }
    return []
  }

  if(!currentUnit || !studentWork) {
    return false;
  }

  const getCurrentModule = () => {
    var currentModule = null;
    if(moduleId) {
      currentUnit.lessons.forEach(lesson => {
        lesson.modules.forEach(mod => {
          if(mod.id === parseInt(moduleId)) {
            currentModule = mod;
          }
        })
      })
    }
    return currentModule;
  }

  return (
    <>
      <div className="my-work">
          <div className={"panel card" + (myWorkActive ? '' : ' hidden')} style={{ width : panelWidth }}>
              <div className="card-header" >
                  <h4 >My Work</h4>
                   <PDFDownloadLink document={<MyWorkPdf courses={courses} user={user} courseId={courseId} currentUnit={currentUnit} studentNotes={studentNotes} getWork={getWork} />} fileName={"Form"}>
                     {
                       ({loading}) => (loading? 'Loading document': "Download")
                     }
                   </PDFDownloadLink>
              </div>
              <div className="card-body">
                  {studentNotes.filter(note => JSON.parse(note.content).unit === currentUnit.id).filter(note => JSON.parse(note.content).instance === null).length > 0 && <p style={{marginBottom: '5px'}}><i style={{margin: '0px 0px 3px 0px', fontSize:'15px'}} className="h5 text-nav ai-edit"></i> Notes</p>}
                  <div style={{display:'flex', flexDirection:'column'}}>
                    <ol>
                      {
                        studentNotes.filter(note => JSON.parse(note.content).unit === currentUnit.id).filter(note => JSON.parse(note.content).instance === null).map((note, i2) => {
                          return (
                            // <div key={`unit-note-${i2}`}>{JSON.parse(note.content).content}</div>
                            <li key={'unit-note-li-' + i2} >
                              <a className="note-link" onClick={()=> setModalData({type: 'note', data: note})}  key={`unit-note-${i2}`}>{JSON.parse(note.content).title}</a>
                            </li>
                          )
                        })
                      }

                    </ol>
                  </div>
                  {
                    !moduleId ?
                    <Accordion>
                    {currentUnit.lessons.map((lesson, i) => {
                      const unitNotes = studentNotes.filter(note => parseInt(JSON.parse(note.content).unit) === currentUnit.id)
                      console.log(unitNotes, lesson);
                        return (
                          <div key={`lesson-work-${i}`}>
                            <Accordion.Item eventKey={i} key={`title-accordion-${i}`}>
                              <Accordion.Header>
                                <h5 style={{marginBottom: 0, fontWeight: 'normal'}}>{lesson.name}</h5>
                              </Accordion.Header>
                              <Accordion.Body>
                                <Accordion>
                                  {lesson.modules.filter(mod => mod.modname!=='label').map((mod, i2) => {
                                    const thesePersonalNotes = unitNotes.filter((note) => parseInt(JSON.parse(note.content).module) === mod.id)
                                    return (
                                      <Accordion.Item eventKey={i2} key={`work-accordion-${i2}`}>
                                        <Accordion.Header style={{fontSize: '15px', padding: 0}} >
                                          <p  style={{fontSize: '15px', margin:'0px'}}> {mod.name}</p>
                                        </Accordion.Header>
                                        <Accordion.Body style={{display: 'flex', flexDirection:'column'}}>
                                        {getWork(mod).length > 0 ? <p style={{marginBottom: '5px'}}>{mod.modname === 'forum' ? "Posts" : 'Submissions'}</p> : <p>Nothing submitted yet</p>}
                                        <ol>

                                          {
                                            getWork(mod).filter(submission => submission.userid === user.userid).map((results, i3) => {
                                              if(results.plugins && results.plugins.length > 0 && results.plugins[0].fileareas && results.plugins[0].fileareas.length > 0 && results.plugins[0].fileareas[0].files && results.plugins[0].fileareas[0].files.length > 0){
                                                return results.plugins[0].fileareas[0].files.map((file,i7) => file.filename.length > 0 ? <li key={"linktag-li-"+i7}><a key={"linktag-"+i7} className="note-link" href={file.fileurl + "?token=" + user.token} >{file.filename}</a></li>:false)
                                              }
                                              else if(results.plugins[0].editorfields){
                                                return (
                                                  <li key={`work-${i3}`} >
                                                    <a className="note-link" onClick={()=> setModalData({type: 'post', data: results, moduleName: mod.name})} dangerouslySetInnerHTML={{ __html: results.message ? results.message: results.plugins[0].editorfields[0].text }}></a>
                                                  </li>
                                                  )

                                              }
                                            })
                                          }
                                          </ol>
                                          {<p style={{marginBottom: '5px'}}><i style={{margin: '0px 0px 3px 0px', fontSize:'15px'}} className="h5 text-nav ai-edit"></i> Notes</p>}
                                          {
                                            !moduleId ?
                                              <div>
                                                {thesePersonalNotes.length === 0 ? <p>No notes</p> :
                                                  thesePersonalNotes.map((note => {
                                                    return (
                                                      <p>{JSON.parse(note.content).text}</p>
                                                    );
                                                  }))}
                                              </div>
                                            :
                                              <Note fetchNotes={fetchNotes} note={studentNotes.filter(note => parseInt(JSON.parse(note.content).module) === parseInt(mod.id))[0]} user={user} moduleId={mod.id} unitId={unitId} courseId={courseId}></Note>
                                          }
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    )
                                  })}
                                </Accordion>
                              </Accordion.Body>
                            </Accordion.Item>
                          </div>
                        )
                    })}
                    </Accordion>
                    :
                     <>
                      <p>
                        Write your thoughts or personal notes here and save them for your convenience.
                      </p>
                      <Note fetchNotes={fetchNotes} note={studentNotes.filter(note => parseInt(JSON.parse(note.content).module) === parseInt(moduleId))[0]} user={user} moduleId={moduleId} unitId={unitId} courseId={courseId}></Note>
                    </>

                  }
               </div>
          </div>

          <button className="btn btn-primary toggle" onClick={() => setMyWorkActive(!myWorkActive)}>
              <i className="ai-book"></i>
          </button>
      </div>
      <Modal size="md" show={modalData} onHide={() => setModalData(false)}>
        {
          modalData.type === "add" ?
            <AddNote closeModal={() => setModalData(false)} data={modalData.data} currentUnit={currentUnit} currentModule={getCurrentModule()}  fetchNotes={fetchNotes} courseId={courseId} user={user}/>:
          modalData.type === "note"?
            <Note fetchNotes={fetchNotes} closeModal={() => setModalData(false)} data={modalData.data} currentUnit={currentUnit} moduleName={modalData.moduleName}/>:
          modalData.type === "post"?
            <Post closeModal={() => setModalData(false)} data={modalData.data} moduleName={modalData.moduleName}/>:
          null
        }
      </Modal>
    </>
  )
}

export default MyWork;
