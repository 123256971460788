import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { loginSuccess, useUserContext } from '../../contexts/UserContext';
import { uploadFile } from '../../api/modules';
import { getUser, updateUserInfo, updateUserProfileImage } from '../../api/user';
import { Navigate } from 'react-router-dom';

function ProfileGeneral() {
  const { user, dispatch } = useUserContext()

  const [ username, setUsername ] = useState('');
  const [ firstname, setFirstname ] = useState('');
  const [ lastname, setLastname ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ currentFile, setCurrentFile ] = useState(false);
  const [ changeAvatar, setChangeAvatar ] = useState(false);
  const [ dataChanged, setDataChanged ] = useState(false);
  const [ imageChanged, setImageChanged ] = useState(false);
  const [ error, setError ] = useState(false);

  useEffect(() => {
    if(user.useremail){
      setUsername(user.username)
      setFirstname(user.userfirstname)
      setLastname(user.userlastname)
      setEmail(user.useremail)

    }
  }, [user]);
  
  useEffect(() => {
    if(username !== user.username || email !== user.useremail || firstname !== user.userfirstname || lastname !== user.userlastname){
      setDataChanged(true)
    }
    else {
      setDataChanged(false)
    }
  }, [username, email, firstname, lastname]);

  const fileChange = (e) => {
    setImageChanged(true)
    let file = e.target.files[0];
    getBase64(file, (result) => {
      setCurrentFile({ file : result, name : file.name });
    });
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
  }

  const saveChanges = async () => {
    if(currentFile && imageChanged){
      const uploadResponse = await uploadFile(currentFile, user.userid, user.token)
      const updateResponse = await updateUserProfileImage(uploadResponse.itemid, user.token)
      const token = window.localStorage.getItem('fnesc-user-token')
      setChangeAvatar(false)
    }
    if(username && firstname && lastname && email && dataChanged){
      if(!email.includes("@") || !email.includes(".")){
        setError("Must be a valid email.")
      }
      else {
        const response = await updateUserInfo(user.userid, username, firstname, lastname, email, user.token)
        if(response !== null){
          setError(response)
        }
        else {
          const newUser = await getUser(user.token);
          dispatch(loginSuccess(user.token, newUser))
        }
      }
    }
  }
  return (
    <div className="d-flex flex-column h-100 bg-light rounded-3 shadow-lg p-4">
      <div className="py-2 p-md-3">
        <div className="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-start">
          <h1 className="h3 mb-2 text-nowrap">Profile info</h1>
        </div>
        <div className="bg-secondary rounded-3 p-4 mb-4">
          <div className="d-block d-sm-flex align-items-center"><img className="d-block rounded-circle mx-sm-0 mx-auto mb-3 mb-sm-0" src={currentFile ?currentFile.file:  user.userimage} alt="Amanda Wilson" width="110" />
            <div className="ps-sm-3 text-center text-sm-start">
              {!changeAvatar &&<button className="btn btn-light shadow btn-sm mb-2" onClick={() => {setError(false);setChangeAvatar(true)}} type="button">Change avatar</button>}
             {changeAvatar && <Form.Control onChange={(e) => fileChange(e)} type="file" />}
              {/* <div className="p mb-0 fs-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div> */}
            </div>
          </div>
        </div>
        {error && <div className="alert alert-danger" role="alert">
          {error}
        </div>}
        <div className="row">
          <div className="col-sm-6">
            <div className="mb-3 pb-1">
              <label className="form-label px-0"  htmlFor="account-fn">First Name</label>
              <input className="form-control" type="text" id="account-fn" onChange={(e)=> {setError(false); setFirstname(e.target.value)}}  value={firstname} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3 pb-1">
              <label className="form-label px-0" htmlFor="account-ln">Last Name</label>
              <input className="form-control" type="text" id="account-ln" onChange={(e)=> {setError(false); setLastname(e.target.value)}}  value={lastname} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3 pb-1">
              <label className="form-label px-0" htmlFor="account-email">Email address</label>
              <input className="form-control" type="email" id="account-email" onChange={(e)=> {setError(false); setEmail(e.target.value.replace(/\s/g, ''))}}  value={email} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3 pb-1">
              <label className="form-label px-0" htmlFor="account-username">Username</label>
              <div className="input-group"><span className="input-group-text">@</span>
                <input className="form-control" type="text" id="account-username" onChange={(e)=> {setError(false); setUsername(e.target.value.replace(/\s/g, ''))}} value={username} />
              </div>
            </div>
          </div>
          <div className="col-12">
            <hr className="mt-2 mb-4" />
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <button className="btn btn-primary mt-3 mt-sm-0" disabled={!dataChanged && !imageChanged} type='submit' onClick={saveChanges}><i className="ai-save fs-lg me-2"></i>Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileGeneral;
