import { BrowserRouter as Router } from "react-router-dom";
import Routing from './Routing';
import ScrollToTop from './utils/ScrollToTop';

function App() {

  return (
    <Router>
      <ScrollToTop />
      <Routing />
    </Router>
  );
}

export default App;
