import { useEffect, useState } from 'react';
import { useLocation, Routes, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Courses from './pages/Courses';
import Course from './pages/Course';
import Unit from './pages/Unit';
import Module from './pages/Module';
import TopNav from './components/TopNav';

import { useUserContext, loginSuccess, loginFail } from './contexts/UserContext';
import { getUser } from './api/user';
import Avatar from './pages/Avatar';
import PasswordRecovery from './pages/PasswordRecovery';

function App() {

  const { dispatch } = useUserContext()
  const location = useLocation();

  const [transitionName, setTransitionName] = useState("next");

  useEffect(() => {
    console.log(window.localStorage.getItem('fnesc-user-token'))
    if(window.localStorage.getItem('fnesc-user-token')) {
      quickLogin();
    }
  }, [])

  const quickLogin = async () => {
    try {
      const token = window.localStorage.getItem('fnesc-user-token');
      const newUser = await getUser(token);
      dispatch(loginSuccess(token, newUser))
    } catch (error) {
      dispatch(loginFail(error))
    }
  }

  return (
    <div>
      <TopNav />
      <main className="page-wrapper">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/avatar-creator" element={<Avatar />} />
          <Route path="/courses" element={<Courses />}>
            <Route path=":courseId" element={<Course />}>
              <Route path="unit/:unitId" element={<Unit />}>
                <Route path=":moduleId" element={<Module />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </main>
    </div>
  );
}
//   <TransitionGroup>
//     <CSSTransition
//       key={location.pathname}
//       classNames={transitionName}
//       timeout={500}
//     >
//   </CSSTransition>
// </TransitionGroup>

export default App;
