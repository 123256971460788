import { useNavigate, useParams } from "react-router-dom";
import { useCoursesContext } from '../contexts/CoursesContext';

function CourseBreadcrumbs() {
  const navigate = useNavigate();
  const { courseId, unitId, moduleId } = useParams();
  const { courses : { allCourses, allUnits }, dispatch } = useCoursesContext()

  const currentCourse = allCourses.find(course => course.id === parseInt(courseId));
  const currentUnit = allUnits.find(unit => unit.id === parseInt(unitId));
  var currentModule = false
  if(currentUnit) {
    currentUnit.lessons.forEach(lesson => {
      lesson.modules.forEach(module => {
        if(module.id === parseInt(moduleId)) {
          currentModule = module;
        }
      })
    })
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="py-1 breadcrumb">
        <li className="breadcrumb-item"><a onClick={() => navigate('/')} href="#">Home</a></li>
        <li className="breadcrumb-item"><a onClick={() => navigate('/courses')} href="#">Courses</a></li>
        <li className={`breadcrumb-item ${!unitId ? 'active' : ''}`} aria-current="page">
          <a onClick={() => navigate(`/courses/${courseId}`)}  dangerouslySetInnerHTML={{__html : currentCourse.fullname}} href="#"></a>
        </li>
        {currentUnit ?
          <li className={currentModule ? "breadcrumb-item" : "breadcrumb-item active"} aria-current="page">
            <a onClick={() => navigate(`/courses/${courseId}/unit/${unitId}`)} dangerouslySetInnerHTML={{__html : currentUnit.name}} href="#"></a>
          </li>
        : false}
        {currentModule ? <li className="breadcrumb-item active" aria-current="page" dangerouslySetInnerHTML={{__html : currentModule.name}}></li> : false}
      </ol>
    </nav>
  );
}

export default CourseBreadcrumbs;
