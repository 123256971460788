import { useEffect } from 'react'
import { useUserContext, moreDataSuccess } from '../contexts/UserContext';
import { getMoreUserData } from '../api/user';

import ProfileGeneral from '../components/profile/General';
import ProfileSideNav from '../components/profile/SideNav';
import AvatarCreator from '../components/AvatarCreator';

function Avatar() {

  const { user, dispatch } = useUserContext()

  useEffect(() => {
    if(user.username && !user.useremail) {
      getEmailData();
    }
  }, [user])

  const getEmailData = async() => {
    const additionalData = await getMoreUserData(user.username, user.token)
    dispatch(moreDataSuccess(additionalData))
  }

  return (
    <div style={{backgroundColor : '#f7f7fc'}}>
      <div className="position-relative" style={{backgroundImage: "url('img/demo/presentation/intro/bg.jpg')", height: 500}}>
        <div className="shape shape-bottom shape-slant bg-secondary d-none d-lg-block">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 260">
            <polygon fill="currentColor" points="0,257 0,260 3000,260 3000,0"></polygon>
          </svg>
        </div>
      </div>
      <div className="container position-relative zindex-5 pb-4 mb-md-3" style={{marginTop: -350}}>
        <div className="row">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <ProfileSideNav />
          </div>
          <div className="col-lg-8">
            <AvatarCreator />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Avatar;
