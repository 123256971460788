import { memo, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { Box } from './Box'
const style = {
  minHeight: '12rem',
  width: '12rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'black',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  borderWidth:".5px",
  border:"solid",
  borderRadius:"10px"
}

const venStyles = {
  minHeight: '25rem',
  minWidth: '25rem',
  padding:'50px',
  borderRadius:'100%',
  marginRight:-300
}


export const Dustbin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  name,
  items,
  isVen
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = '#222'
  if (isActive) {
    backgroundColor = 'lightgrey'
  }
  else {
    backgroundColor = 'white'

  }

  // useEffect(() => {
  //   console.log(items)
  // }, [items])

  return (
    <div ref={drop} style={!isVen ? {...style, backgroundColor }: { height:'50%'}} data-testid="dustbin">
      <p>{name}</p>

      {items && items.map((item, i) => {
        return ( <Box
          name={item}
          type={'box'}
          key={i}
        />)
      })}

    </div>
  )
})
