import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap"

export const Post = ({data, closeModal, moduleName}) => {
    useEffect(() => {
        console.log(data.plugins.find(submission => submission.type === "onlinetext").editorfields[0].text)
    }, []);
    return (
        <>
            <Modal.Header>Submission for '{moduleName}'</Modal.Header>
            <Modal.Body>
                <h4>{data.subject}</h4>
                <div>
                    <div dangerouslySetInnerHTML={{__html: data.plugins.find(submission => submission.type === "onlinetext").editorfields[0].text}}></div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal} variant="secondary">Close</Button>
            </Modal.Footer>
        </>
    )
}