import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { setUnits, useCoursesContext } from '../../contexts/CoursesContext';
import { useUserContext } from '../../contexts/UserContext';
import { Button, Modal } from 'react-bootstrap'
import { getLessonInfo, startLessonAttempt, getLessonAttempts, getAttemptsOverview, getLessonPages, getPageData, viewLesson } from '../../api/modules';
import $ from 'jquery';
import Assign from './Assign';
import { getUnits } from "../../api/courses";

const hardcodedMoodleJumpToValues = {
  "0" : 'LESSON_THISPAGE',
  "1" : 'LESSON_UNSEENPAGE',
  "2" : 'LESSON_UNANSWEREDPAGE',
  "-1" : 'LESSON_NEXTPAGE',
  "-9" : 'LESSON_EOL',
  "-50" : 'LESSON_UNSEENBRANCHPAGE',
  "-40" : 'LESSON_PREVIOUSPAGE',
  "-60" : 'LESSON_RANDOMPAGE',
  "-70" : 'LESSON_RANDOMBRANCH',
  "-80" : 'LESSON_CLUSTERJUMP',
  "-99" : 'LESSON_UNDEFINED'
}

function Lesson({ currentModule }) {

    const { courseId, unitId } = useParams();
    const { user } = useUserContext()
    const { courses : { allUnits }, dispatch } = useCoursesContext()
    const [ lessonContent, setLessonContent ] = useState(null)
    const [ lessonPages, setLessonPages ] = useState([])
    const [ currentPageID, setCurrentPageID ] = useState(null)
    const [ currentPageData, setCurrentPageData ] = useState(null)
    const [ lessonDone, setLessonDone ] = useState(false)

    const [ assignmentModule, setAssignmentModule ] = useState(null)
    useEffect(() => {
      if(lessonDone){
        markViewLesson()
      }
    }, [lessonDone]);
    useEffect(() => {
      if(currentModule) {
        fetchModuleContent(currentModule)
      }
    }, [currentModule])
    
    useEffect(() => {
      if(currentPageID && currentModule) {
        fetchPageContent();
      }
    }, [currentPageID])

    const markViewLesson =  async () => {
      await viewLesson(currentModule, user.token)
      const units = await getUnits(courseId, user.token);
      dispatch(setUnits(units))
     }

    const clickEvent = (e) =>{

      e.preventDefault();
      const currentUnit = allUnits.find(unit => unit.id === parseInt(unitId));
      var href = $(this).attr('href'); //this seems to be returning undefined
      // https://www.fnesc.xyz/moodle/mod/assign/view.php?id=78
      if(href && href.indexOf('https://www.fnesc.xyz/moodle/mod/assign/view.php') > -1) {
        var newAssignmentID = href.split('?id=')[1];
        var thisAssignmentModule = false;
        currentUnit.lessons.forEach(lesson => {
          lesson.modules.forEach(thisModule => {
            if(thisModule.id === parseInt(newAssignmentID)) {
              thisAssignmentModule = thisModule;
            }
          })
        })
        setAssignmentModule(thisAssignmentModule)
      }
    }

    useEffect(() => {
      $(document).on('click', 'a', clickEvent)
      return ()=>{
        $(document).off('click', 'a', clickEvent)
      }
    }, [currentPageData])

    const fetchModuleContent = async (mod) => {
      try {
        const lessonInfo = await getLessonInfo(courseId, mod, user.token);
        setLessonContent(lessonInfo)
        const lessonPages = await getLessonPages(mod, user.token);
        setLessonPages(lessonPages)
        setCurrentPageID(lessonPages[0].page.id)
      } catch (error) {
        console.log('error fetching lesson');
      }
    }

    const fetchPageContent = async() => {
      try {
        const pageData = await getPageData(currentModule, currentPageID, user.token)
        setCurrentPageData(pageData)
      } catch (error) {
        console.log('error fetching page content');
      }
    }

    const setNextPage = (answer) => {
      const thisJumpTo = hardcodedMoodleJumpToValues[answer.jumpto.toString()]
      switch(thisJumpTo) {
        case 'LESSON_NEXTPAGE':
          setCurrentPageID(currentPageData.page.nextpageid ? currentPageData.page.nextpageid : currentPageData.page.id);
          break;
        case 'LESSON_PREVIOUSPAGE':
          setCurrentPageID(currentPageData.page.prevpageid ? currentPageData.page.prevpageid : currentPageData.page.id);
          break;
        case 'LESSON_EOL':
          setLessonDone(true)
          break;
        default:
          setCurrentPageID(answer.jumpto)
          break;
      }
    }

    return (
      <>
      {
        lessonDone ?
        <div>
          <h2>Congrats Lesson Completed</h2>
        </div> : 
          <div>
            <h2>{currentModule.name}</h2>
            {lessonContent ?
              <div>
                <div dangerouslySetInnerHTML={{__html : lessonContent.intro }} />
                {currentPageData ?
                  <div>
                    <h5>{currentPageData.page.title}</h5>
                    <div dangerouslySetInnerHTML={{__html : currentPageData.page.contents }} />
                    <Modal size="xl" show={assignmentModule ? true : false} onHide={() => setAssignmentModule(false)}>
                      <Modal.Body>
                        <Assign currentModule={assignmentModule} />
                      </Modal.Body>
                    </Modal>
                    <hr />
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      {currentPageData.answers.map((answer, i) => {
                        return (
                          <Button key={i} onClick={() => setNextPage(answer)}>{answer.answer}</Button>
                        )
                      })}
                    </div>
                  </div>
                : false}
              </div>
            : false }
        </div>
      }
      </>
    )
}

export default Lesson;
