import { useState, useEffect } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { useCoursesContext, setActive, setUnits } from '../contexts/CoursesContext';
import { useUserContext } from '../contexts/UserContext';
import { getModule, getUnits } from '../api/courses';

import "../style/Unit.scss";
import { Button } from "react-bootstrap";

function Unit() {

    const navigate = useNavigate();
    const { user } = useUserContext()

    const { courseId, unitId } = useParams();
    const { courses : { allUnits }, dispatch } = useCoursesContext()
    const [ progressPoints, setProgressPoints ] = useState({})
    const [ onCurrentLesson, setOnCurrentLesson ] = useState(0)
    const [ onCurrentModule, setOnCurrentModule ] = useState(0)
    const currentUnit = allUnits.find(unit => unit.id === parseInt(unitId));

    useEffect(() => {
      fetchUnits(courseId)
    }, []);

    const fetchUnits = async (courseId) => {
      try {
        const units = await getUnits(courseId, user.token);
        dispatch(setUnits(units))
      } catch (error) {
        console.log('error fetching units');
      }
    }

    useEffect(() => {
      if(currentUnit){
        let onCurrentModule = 0
        let onCurrentLesson = 0
        const progressPointsClone = JSON.parse(JSON.stringify(progressPoints))
        currentUnit.lessons.forEach((lesson, li) => {
          const points = lesson.modules.filter(mod => mod.modname!=='label').map((mod, i) => {
            if(mod.completiondata && mod.completiondata.state === 1){//this is completed
              if(i === lesson.modules.length - 1){
                //are we on the last module for the lesson?
                onCurrentModule = 0
                onCurrentLesson = li + 1
              }
              else {
                onCurrentModule = i + 1
                onCurrentLesson = li
              }
            }
            return { id : mod.id, name : mod.name };
          })
          progressPointsClone[li] = points
        })

        setOnCurrentModule(onCurrentModule)
        setOnCurrentLesson(onCurrentLesson)
        setProgressPoints(progressPointsClone)
      }

    }, [allUnits, currentUnit]);

    if(allUnits.length === 0) {
      return false;
    }

    return (
        <div className="lesson-list-container">
          {/* <h1>{currentUnit && currentUnit.name}</h1> */}
          { currentUnit && currentUnit.lessons.map((lesson, i) => {
            return (
              <article key={`lesson-${lesson.id}`} className="card card-horizontal card-hover mb-grid-gutter">
                  {/* <a className="card-img-top" style={{backgroundImage: "url(/img/blog/01.jpg)"}}></a> */}
                  <div className="card-body" >
                    <h2 className="h4 nav-heading mb-4"><a>{lesson.name}</a></h2>
                      {lesson.modules.find(mod => mod.modname === 'label') ?
                        <div dangerouslySetInnerHTML={{__html : lesson.modules.find(mod => mod.modname === 'label').description }}></div>
                      : false}
                      { progressPoints[i] && progressPoints[i].length > 0 ?
                        <div className="lesson-prog-container">
                          { progressPoints[i].map((mod, j) => {
                            const complete = i < onCurrentLesson || i === onCurrentLesson && j < onCurrentModule ? 'done' : false;
                            const active = i === onCurrentLesson && j === onCurrentModule ? 'active' : false;
                            return (
                                <div className={`prog-item ${active}`} key={`module-${mod.id}`}>
                                    <div> { complete ? <i className="ai-check"></i> : false } </div>
                                    { complete || active ?
                                        <label className="active-link" onClick={() => navigate(`/courses/${courseId}/unit/${unitId}/${mod.id}`)} dangerouslySetInnerHTML={{__html : mod.name}}></label>
                                    :
                                        <label>{mod.name}</label>
                                    }
                                </div>
                            )
                          })}
                        </div>
                      : 'No Content' }

                    {/* <ul className="module-list"> */}
                        {
                        // lesson.modules.map(mod => {
                        //     return (
                        //         // <li key={`module-${mod.id}`} onClick={() => navigate(`/courses/${courseId}/unit/${unitId}/${mod.id}`)} className={'link'}>{mod.name}</li>
                        //     )
                        // })
                        }
                    {/* </ul> */}
                    {/* <div className="mt-3 text-end text-nowrap"><a className="meta-link fs-xs" href="#"><i className="ai-message-square me-1"></i>&nbsp;6</a><span className="meta-divider"></span><a className="meta-link fs-xs" href="#"><i className="ai-calendar me-1 mt-n1"></i>&nbsp;Feb 19</a></div> */}
                  </div>
                </article>
              )
          })
          }
      </div>
    )
}
export default Unit;
