import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from '../../contexts/UserContext';
import { getPageContent, viewPage } from '../../api/modules';
import { getUnits } from "../../api/courses";
import { setUnits, useCoursesContext } from "../../contexts/CoursesContext";
import { Spinner } from "react-bootstrap";

function Page({ currentModule }) {
    const { courseId, unitId } = useParams();

    const [ pageContent, setPageContent ] = useState(false);
    const { user } = useUserContext()
    const { courses : { allUnits }, dispatch } = useCoursesContext()


    useEffect(() => {
      if(currentModule) {
        fetchModuleContent(currentModule)
      }
    }, [currentModule])

    const fetchModuleContent = async (mod) => {
        try {
          const modContent = await getPageContent(mod, user.token);

          await viewPage(mod, user.token);

          setPageContent(modContent);

            if(mod.completiondata.state !== 1){
              const units = await getUnits(courseId, user.token);
              dispatch(setUnits(units))

            }
        } catch (error) {
            console.log('error fetching page');
        }
    }

    return (
        <div>
            <h2>{currentModule.name}</h2>
            {pageContent ? <div dangerouslySetInnerHTML={{__html : pageContent }} /> :

            <div style={{display:"flex", justifyContent:'center'}}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>

          </div>
            }
        </div>
    )
}

export default Page;
