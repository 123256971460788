import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

import { UserProvider } from './contexts/UserContext';
import { CoursesProvider } from './contexts/CoursesContext';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import "./theme/css/theme.min.css";
import "./style/index.scss";

ReactDOM.render(

  <UserProvider>
    <CoursesProvider>
      <App />
    </CoursesProvider>
  </UserProvider>,

document.getElementById("root"));
