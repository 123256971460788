export async function getCourses(userId, token) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?userid=${userId}&wstoken=${token}&wsfunction=core_enrol_get_users_courses&moodlewsrestformat=json`, {
      method : "GET"
      
    }).then(resp => resp.json())
  if (response) return response
  throw new Error("Error fetching courses.")
}

export async function getUnits(courseId, token) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/fnesc-api.php?courseid=${courseId}&wstoken=${token}&wsfunction=get_course_layout`, {
      method : "GET"
    }).then(resp => resp.json())
  if (response) {
      const formattedUnits = [];
      response.forEach(unit => {
          if (!unit.parent) {
              unit.lessons = [];
              formattedUnits.push(unit);
          } else {
              const parentUnit = formattedUnits.find(p => p.section === unit.parent);
              parentUnit && parentUnit.lessons.push(unit);
          }
      })
      formattedUnits.shift();
    return formattedUnits;
  }
  throw new Error("Error fetching courses data.")
}
