import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../contexts/UserContext';

import { Badge } from 'react-bootstrap'

import CourseListComponent from '../components/CourseList';

function Home() {
  const navigate = useNavigate()
  const { user } = useUserContext()

  return (
    <div style={{backgroundColor : '#f7f7fc'}}>
      <div>
        <section className="d-flex align-items-center position-relative bg-dark bg-size-cover bg-position-center min-vh-100 overflow-hidden pt-6 pb-lg-5" style={{backgroundImage: "url(img/demo/presentation/intro/bg.jpg)"}}>
          <div className="container-fluid pt-4 pb-5 py-lg-5">
            <div className="row align-items-center py-3">
              <div className="col-xl-6 col-lg-5 d-flex justify-content-end">
                <div className="pt-2 mx-auto mb-5 mb-lg-0 ms-lg-0 me-xl-7 text-center text-lg-start" style={{maxWidth: '495px'}}>
                  <h1 className="display-4 text-light pb-2">
                    <span className="fw-light">Let's start </span>learning!
                  </h1>
                  <p className="h4 fw-light text-light opacity-70 line-height-base">We're so glad you're here, so we can start learning together.</p>
                  <a className="d-inline-flex align-items-center text-decoration-none pt-2 mt-4 mb-5" href="#demos" data-scroll>
                    <span className="btn btn-icon rounded-circle border-light flex-shrink-0 px-3">
                      <i className="ai-arrow-down h4 text-light my-1"></i>
                    </span>
                    <span className="ms-3 text-light fw-medium">View Courses</span></a>
                  <hr className="hr-light mt-0 mb-5" />
                  <div className="row">
                    <div className="col-sm-4 mb-4 mb-sm-0">
                      <div className="h1 text-light mb-1">3</div>
                      <div className="h5 text-light fw-normal opacity-70 mb-2">Courses</div>
                      <Badge bg="primary">More coming</Badge>
                    </div>
                    <div className="col-sm-4 mb-4 mb-sm-0">
                      <div className="h1 text-light mb-1">50+</div>
                      <div className="h5 text-light fw-normal opacity-70 mb-1">Classmates</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-7">
                <div className="parallax ms-lg-n5" style={{maxWidth : 920}}>
                  <div className="parallax-layer position-relative" data-depth="0.1"><img src="img/demo/presentation/intro/layer01.png" alt="Layer" /></div>
                  <div className="parallax-layer" style={{zIndex: "2"}} data-depth="0.3"><img src="img/demo/presentation/intro/layer02.png" alt="Layer" /></div>
                  <div className="parallax-layer" data-depth="0.2"><img src="img/demo/presentation/intro/layer03.png" alt="Layer" /></div>
                  <div className="parallax-layer" style={{zIndex: "3"}} data-depth="0.1"><img src="img/demo/presentation/intro/layer04.png" alt="Layer" /></div>
                  <div className="parallax-layer" data-depth="0.15"><img src="img/demo/presentation/intro/layer05.png" alt="Layer" /></div>
                  <div className="parallax-layer" style={{zIndex: "4"}} data-depth="0.25"><img src="img/demo/presentation/intro/layer06.png" alt="Layer" /></div>
                  <div className="parallax-layer" data-depth="0.3"><img src="img/demo/presentation/intro/layer07.png" alt="Layer" /></div>
                  <div className="parallax-layer" data-depth="0.4"><img src="img/demo/presentation/intro/layer08.png" alt="Layer" /></div>
                  <div className="parallax-layer" data-depth="0.35"><img src="img/demo/presentation/intro/layer09.png" alt="Layer" /></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
