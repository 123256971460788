import { createContext, useReducer, useContext } from "react";

export const CoursesContext = createContext();

const initialState = {
  allCourses : [],
  allUnits : []
}

export const SET_COURSES = 'SET_COURSES'
export const SET_UNITS = 'SET_UNITS'
export const SET_MODULE_CONTENT = 'SET_MODULE_CONTENT'

export function setCourses(courses) {
  return { type : 'SET_COURSES', courses }
}
export function setUnits(units) {
  return { type : 'SET_UNITS', units }
}

export function coursesReducer(state, action) {
  switch (action.type) {
    case SET_COURSES:
      return {
        ...state,
        allCourses : action.courses
      };
    case SET_UNITS:
      return {
        ...state,
        allUnits : action.units
      };
    default:
      return state;
  }
}

function CoursesProvider(props) {
  const [courses, dispatch] = useReducer(coursesReducer, initialState);

  const coursesData = { courses, dispatch };

  return <CoursesContext.Provider value={coursesData} {...props} />;
}

function useCoursesContext() {
  return useContext(CoursesContext);
}

export { CoursesProvider, useCoursesContext };
