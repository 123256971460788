import { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { useUserContext } from '../contexts/UserContext';
import { useCoursesContext } from '../contexts/CoursesContext';
import { replaceImageLinks } from '../utils/utils';

import "../style/ProgressWave.scss";

function CourseComponent() {
    const navigate = useNavigate();
    const { user } = useUserContext()
    const { courseId, unitId } = useParams();
    const { courses : { allCourses, allUnits }, dispatch } = useCoursesContext()
    const [ markers, setMarkers ] = useState([]);

    const currentUnitIdx = allUnits.findIndex(unit => unit.id === parseInt(unitId));

    useEffect(() => {
        let cnvs = document.getElementById('cnvs')
        const itemHeight = 75;
        cnvs.height = allUnits.length * itemHeight;
        if (cnvs.height < 400) { cnvs.height = 400 };

        let ctx = cnvs.getContext('2d');
        ctx.clearRect(0, 0, cnvs.width, cnvs.height);

        const buffer = 15;
        const cnvsHeight = cnvs.height - buffer;
        const cnvsWidth = cnvs.width;
        const extent = 25;

        // Main track
        ctx.beginPath();
        for (let i = buffer; i < cnvsHeight; i++) {
            const x = cnvsWidth / (!unitId ? 4 : 2) - Math.sin (i * (Math.PI/180)) * extent;
            const y = i;
            ctx.strokeStyle = "#000000";
            ctx.lineWidth = 5;
            ctx.lineTo(x, y);
        }
        ctx.stroke()

         // Progress line
         let numberUnitsDone = 0;
         let numberModulesDoneInUnits = [];
         let totalNumberUnits = allUnits.length;
         let totalNumberModulesInUnits = [];
         allUnits.forEach((unit,i) => {
             //go through all units
             numberModulesDoneInUnits.push(0);
             totalNumberModulesInUnits.push(0);
             if (unit.lessons.length > 0) {
                 unit.lessons.forEach(lesson =>{
                     //go through all lessons
                     lesson.modules.forEach(module => {
                       if(module.modname !== 'label') {
                         if(module.completiondata && module.completiondata.state === 1) {
                             numberModulesDoneInUnits[i] += 1;
                         }
                         totalNumberModulesInUnits[i] += 1;
                       }
                     })
                 })
                 if(totalNumberModulesInUnits[i] === numberModulesDoneInUnits[i]) {
                   numberUnitsDone += 1;
                 }
             }
         });
        const percentageOfEachUnit = 1 / (totalNumberUnits);
        const percentageCompleteUnits = (numberUnitsDone * percentageOfEachUnit);
        const percentDoneModuleInUnit = numberModulesDoneInUnits[numberUnitsDone] / (totalNumberModulesInUnits[numberUnitsDone] !== 0 ? totalNumberModulesInUnits[numberUnitsDone] : 1);
        const percentageCompleteModules = percentDoneModuleInUnit / totalNumberUnits;
        const progress = (percentageCompleteUnits + percentageCompleteModules); // percentage
        const progMax = cnvsHeight * progress;

        ctx.beginPath();
        for (let i = buffer; i < progMax; i++) {
            const x = cnvsWidth / (!unitId ? 4 : 2) - Math.sin (i * (Math.PI/180)) * extent;
            const y = i;
            ctx.strokeStyle = "#35dfc3";
            ctx.lineWidth = 6;
            ctx.lineTo(x, y);
        }
        ctx.stroke()

        let markerCoords = [];
        const points = allUnits.length;
        const step = Math.round((cnvsHeight) / points);
        const markerSize = 20;

        for (let i = buffer; i < cnvsHeight; i++) {
            ctx.beginPath();
            const x = cnvsWidth / 2 - Math.sin (i * (Math.PI / 180)) * extent;
            const y = i;
            const progMax = 0;

            if (i === buffer || i === cnvsHeight - 1 || i % step === 1 ) {
                markerCoords.push({
                    left : x - markerSize / (!unitId ? 0.35 : 2),
                    top : y - markerSize / 2,
                    active : i < progMax
                });
            }
        }
        setMarkers(markerCoords);

    }, [courseId, allUnits]);

    //find latest course
    let lastCompletedUnit = 0
    let foundLCL = false
    allUnits.forEach((unit,i) => {
        //go through all units
        if (!foundLCL) {
            if (unit.lessons.length > 0) {
                let notFinished = false
                unit.lessons.forEach(lesson =>{
                    //go through all lessons
                    lesson.modules.filter(mod => mod.modname!=='label').forEach(module => {
                        if(module.completiondata && module.completiondata.state < 1 ){
                          console.log(module, module.completiondata.state)
                            notFinished = true
                            if(module.modname === 'assign') {
                              notFinished = false;
                            }
                        }
                    })
                })
                if (notFinished) {
                    lastCompletedUnit = i
                    foundLCL = true
                }
                else {
                    lastCompletedUnit = i + 1
                }
            }
        }
    });

    const currentCourse = allCourses.find(course => course.id === parseInt(courseId));
    const unitActive = currentUnitIdx > -1;

    const getProgressBarOffset = () => {
        let ret = 50;
        const cnvs = document.getElementById('cnvs');
        const cnvsHeight = cnvs ? cnvs.height : 0;
        const maxHeight = window.innerHeight - 120;

        if (cnvsHeight > maxHeight && currentUnitIdx > 4) {
           ret -= (currentUnitIdx * 35);
        }

        return ret;
    }

    return (
        <div className="course">
          <div className="row">
            <div className="col">
              { !unitId ?
                  <div>
                    <h2>{currentCourse.fullname}</h2>
                    <div dangerouslySetInnerHTML={{__html : replaceImageLinks(currentCourse.summary, user.token)}}></div>
                  </div>
              : false }
            </div>
              <div className="col" style={{display : window.innerWidth < 992 && unitId ? 'none' : 'block'}}>
                <div className="canvas-wrap" style={{ width : !unitId ? 'auto' : '400px', marginTop : getProgressBarOffset()}}>
                    <canvas id="cnvs" width="200" height="700"></canvas>
                    <div id="canvas-dom">
                        { markers.length-1 === allUnits.length && allUnits.map((unit, i) => {
                            let className = lastCompletedUnit >= i ? 'in-progress' : 'inaccessible';
                            return (
                                <div
                                    key={`unit-marker-${i}`}
                                    className={`unit-title ${className}`}
                                    onClick={() => { navigate(`/courses/${courseId}/unit/${unit.id}`)}}
                                    data-before={i+1}
                                    style={{
                                        top : markers[i].top,
                                        left : markers[i].left
                                    }}
                                >
                                    <label dangerouslySetInnerHTML={{__html : unit.name}}></label>
                                </div>
                            )
                        })}
                        {markers.length > 0?
                          <div
                          className={`unit-title ${lastCompletedUnit === markers.length -1 ? 'in-progress' : 'inaccessible'}`}
                          style={{
                              top : markers[markers.length-1].top,
                              left : markers[markers.length-1].left
                            }}
                            data-before={markers.length}
                            >
                              {
                                //   console.log(lastCompletedUnit, markers.length)
                              }
                              <label>Course Completion</label>
                          </div>
                        : false}
                    </div>
                </div>
              </div>
            </div>
        </div>
    )
}

export default CourseComponent;
