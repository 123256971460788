export async function getToken(username, password) {
  const encodedPW = encodeURIComponent(password);
  const response = await fetch(`${process.env.REACT_APP_API_URL}/login/token.php?username=${username}&password=${encodedPW}&service=fnesc-lms`, {
      method : "POST"
    }).then(resp => resp.json())
  if(response.token) return response.token
  throw new Error("Login unsuccessful.")
}

export async function getUser(token) {
  var service = 'core_webservice_get_site_info';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())

  if(response.userid) return response
  throw new Error("Get user unsuccessful.")
}

export async function getMoreUserData(username, token) {
  var service = 'core_user_get_users_by_field';
  var qs = `&field=username&values[0]=${username}`;
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json${qs}`).then(resp => resp.json())
  if(response[0]) return response[0]
  throw new Error("Get user unsuccessful.")
}

export async function updateUserProfileImage(imageID, token){
  const service = 'core_user_update_picture';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?draftitemid=${imageID}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if(response) return response
  throw new Error("Get user unsuccessful.")
}

export async function updateUserInfo(id, newUsername, newName, newLastName, newEmail, token){
  const service = 'core_user_update_users';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?users[0][id]=${id}&users[0][username]=${newUsername}&users[0][lastname]=${newLastName}&users[0][firstname]=${newName}&users[0][email]=${newEmail}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if(response  === null) return response
  return 'Username may already exist'
}

export async function getUserWork(courseid, userid, token) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/fnesc-api.php?courseid=${courseid}&userid=${userid}&wsfunction=get_work&wstoken=${token}`).then(resp => resp.json())
  if(response) return response
  throw new Error("Get user work unsuccessful.")
}

export async function getUserNotes(courseid, userid, token) {
  var service = 'core_notes_get_course_notes';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?courseid=${courseid}&userid=${userid}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  if(response.coursenotes) return response.coursenotes
  throw new Error("Get user notes unsuccessful.")
}

export async function makeUserNote(content, courseid, userid, token) {
  var service = 'core_notes_create_notes';
  var qs = `&notes[0][userid]=${userid}&notes[0][publishstate]=public&notes[0][courseid]=${courseid}&notes[0][text]=${content}&notes[0][format]=1`
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json${qs}`).then(resp => resp.json())
  if(response.length > 0) return response[0].noteid
  throw new Error("Post user note unsuccessful.")
}


export async function deleteNote(noteID, token){
  var service = 'core_notes_delete_notes';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?notes[0]=${noteID}&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  return response
}

export async function updateNote(content, noteID, token){
  var service = 'core_notes_update_notes';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?&notes[0][publishstate]=public&notes[0][id]=${noteID}&notes[0][text]=${content}&notes[0][format]=1&wstoken=${token}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  return 'hi'
}

export async function sendPassRecoveryRequest(email, token){
  var service = 'core_auth_request_password_reset';
  const response = await fetch(`${process.env.REACT_APP_API_URL}/webservice/rest/server.php?&email=${email}&wsfunction=${service}&moodlewsrestformat=json`).then(resp => resp.json())
  return response
}
